import { useContext } from 'react'
import { ServiceWorkerContext } from 'contexts/ServiceWorkerContext'

export default function useServiceWorker() {
  const context = useContext(ServiceWorkerContext)
  if (!context) {
    throw new Error(
      'useServiceWorker must be used within a ServiceWorkerContext.Provider'
    )
  }

  return context
}
