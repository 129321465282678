import { Input, TextInput } from '@mantine/core'
import { IMaskInput } from 'react-imask'

export default function MaskedInput(props) {
  const { id, label, required, mask, withAsterisk, ...other } = props
  return (
    <Input.Wrapper
      id={id}
      label={label}
      required={required}
      withAsterisk={withAsterisk}
    >
      <TextInput
        required={required}
        component={IMaskInput}
        mask={mask}
        {...other}
      />
    </Input.Wrapper>
  )
}
