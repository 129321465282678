import { forwardRef } from 'react'
import { Box, UnstyledButton } from '@mantine/core'

const FloatingButton = forwardRef(({ children, ...other }, ref) => {
  return (
    <UnstyledButton
      p="md"
      bg="brand"
      ref={ref}
      mx="auto"
      sx={{
        position: 'fixed',
        bottom: 86,
        right: 16,
        transition: 'transform 250ms ease-out',
        borderRadius: 16,
        boxShadow: '0px 2px 12px rgba(0,0,0,0.2)',
        '&:active': { transform: 'scale(0.90)' },
      }}
      {...other}
    >
      <Box c="white" style={{ display: 'flex' }}>
        {children}
      </Box>
    </UnstyledButton>
  )
})

FloatingButton.displayName = 'FloatingButton'

export default FloatingButton
