import { dateFormat } from 'utils'
import { Link } from 'react-router-dom'
import {
  Group,
  Paper,
  Popover,
  Stack,
  Text,
  Alert,
  Anchor,
  Skeleton,
} from '@mantine/core'
import { IconButton } from 'components'
import { InfoCircle } from 'iconsax-react'
import { useRequests } from 'hooks'
import { REQUEST_STATE } from 'hooks/useRequests'
import { routes } from 'navigation/routes'
import es from 'translations/es.json'

export default function RequestsTab() {
  const { data, loading } = useRequests()

  if (loading)
    return (
      <Stack>
        {new Array(2).fill('').map((it, idx) => (
          <Skeleton key={idx} radius="lg" height={103} />
        ))}
      </Stack>
    )

  if (!loading && data.length === 0)
    return (
      <Alert color="brand" my={12} radius="lg">
        <Text>
          Aun no has enviado ninguna solicitud de certificación, dirigete a{' '}
          <Anchor underline component={Link} to={routes.request} fw={500}>
            Nueva Solicitud de certificación
          </Anchor>{' '}
          para enviar tu primera solicitud.
        </Text>
      </Alert>
    )

  return (
    <>
      <Stack>
        {data.map((request) => (
          <RequestItem key={request.id} {...request} />
        ))}
      </Stack>
    </>
  )
}

const RequestItem = ({ state, date, category, id }) => {
  const stateProps = () => {
    switch (state) {
      case REQUEST_STATE.ACCEPTED:
        return { bg: 'green.1', c: 'green.9' }
      case REQUEST_STATE.REJECTED:
        return { bg: 'red.1', c: 'red.9' }
      default:
        return { bg: 'blue.1', c: 'blue.9' }
    }
  }

  return (
    <Paper p="md" withBorder radius="lg">
      <Group position="apart">
        <Group>
          <Text fz="sm" c="dimmed">
            Fecha de la solicitud:
          </Text>
          <Text fz="sm">{dateFormat(date, 'dd MMM yyyy')}</Text>
        </Group>
        {state === REQUEST_STATE.REJECTED && (
          <Popover
            width={250}
            position="bottom-end"
            withArrow
            shadow="md"
            radius="lg"
          >
            <Popover.Target>
              <IconButton size="sm">
                <InfoCircle />
              </IconButton>
            </Popover.Target>
            <Popover.Dropdown>
              <Text size="sm">
                Su solicitud ha sido rechazada porque alguno de los campos o
                archivos adjuntados no es correcto. Por favor, pongase en
                contacto con nosotros.
              </Text>
            </Popover.Dropdown>
          </Popover>
        )}
      </Group>
      <Group>
        <Text fz="sm" c="dimmed">
          Certificación:
        </Text>
        <Text fz="sm">{category}</Text>
      </Group>
      <Group position="apart">
        <Group>
          <Text fz="sm" c="dimmed">
            ID:
          </Text>
          <Text fz="sm">{id}</Text>
        </Group>
        <Text
          fz="sm"
          inline
          py={6}
          px={10}
          sx={{ borderRadius: 6 }}
          {...stateProps()}
        >
          {es[state]}
        </Text>
      </Group>
    </Paper>
  )
}
