import { useReducer } from 'react'
import useAuth from './useAuth'
import useFirebase from './useFirebase'

export const useMutations = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { loading: false, error: null }
  )
  const { user } = useAuth()
  const { acceptUseConditions } = useFirebase()

  const acceptUse = async () => {
    setState({ loading: true })
    const result = await acceptUseConditions({ userDocId: user.userDocId })
    setState({ loading: false })
    return result
  }

  return { acceptUse, ...state }
}
