import { db, storage, auth } from 'configs/firebaseConfig'
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
  getDocs,
  addDoc,
  doc,
  deleteDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  setDoc,
  collectionGroup,
  Timestamp,
} from 'firebase/firestore'
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import { DEBUG } from 'utils'
import { parseFirebaseDate } from 'utils/firebase'

class TooManyDocumentsError extends Error {
  constructor(message) {
    super(message)
    this.name = 'TooManyDocumentsError'
  }
}

const maxDocByCategory = 10

export default function useFirebase() {
  const rootPath = 'production'

  const generalsColl = collection(db, `${rootPath}/root/generals`)
  const usersColl = collection(db, 'users')
  const notificationsColl = collection(db, 'notifications')
  const seenByColl = (notificationDocId) =>
    collection(db, `notifications/${notificationDocId}/seenBy`)
  const recordsColl = collection(db, `${rootPath}/root/records`)
  const candidatesColl = collection(db, `${rootPath}/root/candidates`)
  const categoriesColl = collection(db, `${rootPath}/root/categories`)
  const centersColl = collection(db, `${rootPath}/root/centers`)
  const examinersColl = collection(db, `${rootPath}/root/examiners`)
  const requestsColl = collection(db, `${rootPath}/root/requests`)
  const certificatesColl = (catDocId) =>
    collection(db, categoriesColl.path, catDocId, 'certificates')
  const examsColl = (candidateDocId) =>
    collection(db, candidatesColl.path, candidateDocId, 'exams')
  const invoicesColl = (userDocId) =>
    collection(db, usersColl.path, userDocId, 'invoices')
  const calendarColl = collection(db, 'calendar')

  const storageInvoicesRef = (userId) =>
    ref(storage, `${rootPath}/candidates/${userId}/invoices`)
  const storageRequestRef = (requestId) =>
    ref(storage, `${rootPath}/requests/${requestId}`)

  //QUERIES
  const getDataByKey = async ({ key, value, coll, orderKey, unique }) => {
    const result = []
    const order = orderKey ? orderKey : 'id'
    const q = query(coll, where(key, '==', value))

    const querySnapshot = await getDocs(q)

    if (querySnapshot.empty) return null

    querySnapshot.docs.forEach((doc) => {
      const docObject = { data: doc.data(), docId: doc.id }
      result.push(docObject)
    })

    result.sort((a, b) => a.data[order] - b.data[order])

    if (unique) {
      return result[0]
    } else {
      return result
    }
  }

  const getNestedCollection = async ({ collName, key, value }) => {
    try {
      const q = query(collectionGroup(db, collName), where(key, '==', value))
      const querySnapshot = await getDocs(q)
      const matchingDocuments = []
      for (const document of querySnapshot.docs) {
        const data = document.data()
        const parentPath = document.ref.parent.path
          .split('/')
          .slice(0, -1)
          .join('/')
        const docRef = doc(db, parentPath)
        const docSnap = await getDoc(docRef)
        const parent = docSnap.data()
        const parentDocId = docSnap.id
        matchingDocuments.push({
          data,
          parent: { ...parent, docId: parentDocId },
        })
      }

      return matchingDocuments
    } catch (error) {
      if (DEBUG) {
        console.log({ error })
      }
      return null
    }
  }

  const getAllDocuments = async ({
    coll,
    orderKey,
    max,
    sort,
    whereKey,
    whereValue,
    whereFilter,
  }) => {
    const documents = []
    const order = orderKey ? orderKey : 'id'
    const sortValue = sort ? sort : 'asc'
    const whereF = whereKey && where(whereKey, whereFilter, whereValue)
    let q

    if (max && whereF) {
      q = query(coll, whereF, orderBy(order, sortValue), limit(max))
    } else if (max && !whereF) {
      q = query(coll, orderBy(order, sortValue), limit(max))
    } else if (!max && whereF) {
      q = query(coll, whereF, orderBy(order, sortValue))
    } else {
      q = query(coll, orderBy(order, sortValue))
    }

    const querySnapshot = await getDocs(q)
    querySnapshot.docs.forEach((doc) => {
      const docObject = {
        docId: doc.id,
        data: doc.data(),
      }
      documents.push(docObject)
    })

    return documents
  }

  const getCount = async (docId) => {
    const documentRef = doc(db, generalsColl.path, docId)

    const count = await getDoc(documentRef).then((snap) => {
      return snap.data().numberOfDocs
    })

    return count
  }

  const getUserCategories = async (candidateDocId) => {
    let categories = []
    const exams = await getAllDocuments({
      coll: examsColl(candidateDocId),
      orderKey: 'record',
    })

    const dbCategories = await getAllDocuments({
      coll: categoriesColl,
    })

    const examsWithCertificate = exams.filter((exam) => {
      return typeof exam.data.certificate === 'number'
    })

    const categoriesIds = examsWithCertificate.map((exam) => exam.data.category)
    dbCategories.forEach(({ data }) => {
      if (categoriesIds.includes(data.id)) {
        const categoryObj = {
          value: data.id,
          label: `${data.code} - ${data.name}`,
        }
        categories.push(categoryObj)
      }
    })

    return categories
  }

  const getUserNotifications = async ({ interests, uid }) => {
    const topicQuery = query(notificationsColl, where('topic', 'in', interests))
    const userQuery = query(notificationsColl, where('user', '==', uid))

    const topicQuerySnapshot = await getDocs(topicQuery)
    const userQuerySnapshot = await getDocs(userQuery)

    const topicResults = topicQuerySnapshot.docs.map((document) => {
      const { body, title, created_at, link, topic } = document.data()
      return { docId: document.id, title, body, created_at, link, topic }
    })
    const userResults = userQuerySnapshot.docs.map((document) => {
      const { body, title, created_at, link, topic } = document.data()
      return { docId: document.id, title, body, created_at, link, topic }
    })

    let notifications = topicResults
      .concat(userResults)
      .sort((a, b) => b.created_at.toDate() - a.created_at.toDate())

    for (const notification of notifications) {
      const path = seenByColl(notification.docId).path + '/' + uid
      const docRef = doc(db, path)
      const docSnap = await getDoc(docRef)
      notification.seen = docSnap.exists()
    }

    return notifications
  }

  const getCalendarEvents = async () => {
    const daysDiff = 6 // A week including current day
    const now = new Date().getTime()
    const diff = now + daysDiff * 24 * 60 * 60 * 1000
    const dateToCompare = Timestamp.fromDate(new Date(diff))

    const eventsQuery = query(
      calendarColl,
      where('date', '>=', dateToCompare),
      orderBy('date', 'asc')
    )
    const centersPromise = getDocs(centersColl)
    const eventsPromise = getDocs(eventsQuery)

    const [centersSnap, eventsSnap] = await Promise.all([
      centersPromise,
      eventsPromise,
    ])

    const centers = centersSnap.docs.map((center) => center.data())
    // Group events by date
    const events = {}
    eventsSnap.docs.forEach((event) => {
      const data = event.data()
      const id = event.id
      const date = parseFirebaseDate(data.date)
      const center = centers.find((center) => center.id === data.center)
      let centerName = ''

      if (center) {
        centerName = center.name
      }

      const eventObj = {
        id,
        ...data,
        date,
        center: centerName,
        centerId: center.id,
      }
      if (!events[date]) {
        events[date] = []
      }

      events[date].push(eventObj)
    })

    return events
  }

  //MUTATIONS
  const createUser = async (data) => {
    try {
      const docRef = await addDoc(usersColl, data)
      return { docId: docRef.id, error: null }
    } catch (error) {
      return {
        error: { message: 'Error creating user.', code: 'error_create_user' },
        docId: null,
      }
    }
  }

  const updateUser = async ({ data, docId }) => {
    const documentRef = doc(db, usersColl.path, docId)
    await updateDoc(documentRef, data)
  }

  const updateCandidate = async ({ data, docId }) => {
    const documentRef = doc(db, candidatesColl.path, docId)
    await updateDoc(documentRef, data)
  }

  const uploadFile = async ({ storageRef, file, fileName }) => {
    const name = fileName ? fileName : file.name
    const fileRef = ref(storage, `${storageRef}/${name}`)

    try {
      const snapshot = await uploadBytes(fileRef, file)
      const url = await getDownloadURL(snapshot.ref)
      return url
    } catch (error) {
      if (DEBUG) {
        console.log(error)
      }
    }
  }

  const removeFile = async ({ storageRef, fileName, onCompleted }) => {
    const fileRef = ref(storage, `${storageRef}/${fileName}`)

    await deleteObject(fileRef)
      .then(() => {
        if (DEBUG) {
          console.log('File deleted successfully.')
        }
        onCompleted && onCompleted()
      })
      .catch((error) => {
        if (DEBUG) {
          console.log(`An error deleting the file have happened: ${error}`)
        }
      })
  }

  const addInvoice = async ({ userDocId, uid, data }) => {
    const collectionRef = invoicesColl(userDocId)
    // Check current documents count
    const q = query(collectionRef, where('category', '==', data.category))
    const querySnapshot = await getDocs(q)
    const count = querySnapshot.size

    // console.log({ count })
    if (count === maxDocByCategory) {
      throw new TooManyDocumentsError(
        `Se ha superado el número máximo de documentos (${maxDocByCategory}) por categoría.`
      )
    }

    const storageRef = storageInvoicesRef(uid)
    const fileUrl = await uploadFile({
      storageRef,
      file: data.file,
      fileName: data.file.name,
    })

    const newDataObject = {
      ...data,
      date: serverTimestamp(),
      file: { name: data.file.name, type: data.file.type, url: fileUrl },
    }

    const docRef = await addDoc(collectionRef, newDataObject)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const docData = docSnap.data()
      return { docId: docRef.id, ...docData }
    }
  }

  const removeInvoice = async ({ uid, userDocId, fileName, invoiceDocId }) => {
    const storageRef = storageInvoicesRef(uid)
    const collectionPath = invoicesColl(userDocId).path
    await removeFile({
      storageRef,
      fileName,
    })

    await deleteDoc(doc(db, collectionPath, invoiceDocId))
  }

  const deleteDocumentByKey = async ({ coll, key, value, onCompleted }) => {
    const q = query(coll, where(key, '==', value))
    let docId
    const querySnapshot = await getDocs(q)
    querySnapshot.docs.forEach((doc) => {
      docId = doc.id
    })

    await deleteDoc(doc(db, coll.path, docId)).then(
      () => onCompleted && onCompleted()
    )
  }

  const addRequest = async ({ data }) => {
    try {
      const documentRef = await addDoc(requestsColl, {
        ...data,
        files: [],
        sign: '',
      })

      // Parallel upload for signature and files
      const uploadSignature = uploadFile({
        storageRef: storageRequestRef(documentRef.id),
        file: data.sign,
        fileName: 'signature.png',
      })

      const fileUploads = data.files.map((file) =>
        uploadFile({
          storageRef: storageRequestRef(documentRef.id),
          file: file.data,
          fileName: file.data.name,
        })
      )

      const [signUrl, ...uploadedFilesUrls] = await Promise.all([
        uploadSignature,
        ...fileUploads,
      ])

      // Construct files array
      const files = uploadedFilesUrls.map((url, index) => ({
        name: data.files[index].data.name,
        type: data.files[index].data.type,
        url,
      }))

      // Update the document with file URLs
      await updateDoc(documentRef, { files, sign: signUrl })
    } catch (err) {
      console.error('Error in addRequest function:', err)
      throw new Error(err)
    }
  }

  const markAsRead = async ({ docId, uid }) => {
    await setDoc(doc(db, seenByColl(docId).path, uid), {
      date: serverTimestamp(),
    })
  }

  const acceptUseConditions = async ({ userDocId }) => {
    try {
      const docRef = doc(db, usersColl.path, userDocId)
      await updateDoc(docRef, { use_check: true })
      return { error: null, success: true }
    } catch (error) {
      return { error, success: false }
    }
  }

  //LISTENER
  const listenDataByKey = ({
    key,
    value,
    coll,
    orderKey,
    callback,
    unique,
  }) => {
    const order = orderKey ? orderKey : 'id'
    const q = query(coll, where(key, '==', value))

    onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
      let result = []
      const docChanges = snapshot.docChanges()

      docChanges.forEach((change, index) => {
        const data = change.doc.data()
        if (change.type === 'added') {
          const docData = { data: data, docId: change.doc.id }
          result.push(docData)
        }
        // const source = snapshot.metadata.fromCache ? "local cache" : "server";
        // console.log("Data came from " + source);
        if (index === docChanges.length - 1) {
          result &&
            result.length &&
            result.sort((a, b) => a.data[order] - b.data[order])
          if (unique) {
            callback(result[0])
          } else {
            callback(result)
          }
        }
      })
    })
  }

  const listenDocumentsData = ({
    coll,
    orderKey,
    max,
    sort,
    whereKey,
    whereValue,
    whereFilter,
    callback,
  }) => {
    const order = orderKey ? orderKey : 'id'
    const sortValue = sort ? sort : 'asc'
    const whereF = whereKey && where(whereKey, whereFilter, whereValue)
    let q

    if (max && whereF) {
      q = query(coll, whereF, orderBy(order, sortValue), limit(max))
    } else if (max && !whereF) {
      q = query(coll, orderBy(order, sortValue), limit(max))
    } else if (!max && whereF) {
      q = query(coll, whereF, orderBy(order, sortValue))
    } else {
      q = query(coll, orderBy(order, sortValue))
    }

    onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
      let result = []
      const docChanges = snapshot.docChanges()
      docChanges.forEach((change, index) => {
        const data = change.doc.data()
        if (change.type === 'added') {
          const docData = { data: data, docId: change.doc.id }
          result.push(docData)
        }

        if (index === docChanges.length - 1) {
          // const source = snapshot.metadata.fromCache ? "local cache" : "server";
          // console.log("Data came from " + source);
          callback(result)
        }
      })
    })
  }

  return {
    db,
    auth,
    generalsColl,
    usersColl,
    recordsColl,
    candidatesColl,
    categoriesColl,
    centersColl,
    examinersColl,
    requestsColl,
    notificationsColl,
    certificatesColl,
    examsColl,
    invoicesColl,
    storageInvoicesRef,
    storageRequestRef,
    //QUERIES
    getDataByKey,
    getNestedCollection,
    getAllDocuments,
    getCount,
    getUserCategories,
    getUserNotifications,
    getCalendarEvents,
    //LISTENERS
    listenDataByKey,
    listenDocumentsData,
    //MUTATIONS
    createUser,
    updateUser,
    updateCandidate,
    uploadFile,
    removeFile,
    addInvoice,
    removeInvoice,
    deleteDocumentByKey,
    addRequest,
    markAsRead,
    acceptUseConditions,
  }
}
