import { Alert, Container, Text, Title } from '@mantine/core'
import { Button } from 'components'
import { useFirebase } from 'hooks'
import { ArrowLeft, Verify } from 'iconsax-react'
import { routes } from 'navigation/routes'
import { Link } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

export default function EmailSent() {
  const { auth } = useFirebase()
  const email =
    auth.currentUser?.email || window.localStorage.getItem('emailForSignIn')

  if (auth.currentUser?.emailVerified)
    return <Navigate to={routes.home} replace />
  if (!email) return <Navigate to={routes.sign_in} replace />

  return (
    <Container
      fluid
      component="main"
      pt={16}
      pb={64}
      mih="100vh"
      bg="white"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Verify size="96" style={{ color: '#338edf' }} />
      <Title align="center" order={2} mb={12}>
        Correo enviado
      </Title>
      <Text align="center" sx={{ textWrap: 'pretty' }}>
        Hemos enviado un enlace de verificación al siguiente correo electrónico:{' '}
      </Text>
      <Text align="center" fw={700} mb={32}>
        {email}
      </Text>
      <Alert mb={32}>
        <Text align="center" sx={{ textWrap: 'pretty' }}>
          Por favor, comprueba en tu bandeja de entrada (o en correo no
          deseados), y haz clic en el enlace proporcionado para verificar tu
          correo electrónico.
        </Text>
      </Alert>
      <Text align="center" sx={{ textWrap: 'pretty' }}>
        Puedes cerrar esta ventana.
      </Text>
      <Button
        component={Link}
        variant="subtle"
        text="Volver a iniciar sesión"
        to={routes.sign_in}
        leftIcon={<ArrowLeft size={16} />}
      />
    </Container>
  )
}
