import { forwardRef } from 'react'
import { ActionIcon } from '@mantine/core'

const IconButton = forwardRef(
  ({ size = 'lg', sx, children, ...others }, ref) => {
    return (
      <ActionIcon
        ref={ref}
        size={size}
        sx={{
          transition: 'all 200ms ease',
          '&:active': { transform: 'scale(0.85)' },
          ...sx,
        }}
        {...others}
      >
        {children}
      </ActionIcon>
    )
  }
)

IconButton.displayName = 'IconButton'

export default IconButton
