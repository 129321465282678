export const provinces = [
  "ÁLAVA",
  "ALBACETE",
  "ALICANTE",
  "ALMERÍA",
  "ASTURIAS",
  "ÁVILA",
  "BADAJOZ",
  "BARCELONA",
  "BURGOS",
  "CÁCERES",
  "CÁDIZ",
  "CANTABRIA",
  "CASTELLÓN",
  "CIUDAD REAL",
  "CÓRDOBA",
  "LA CORUÑA",
  "CUENCA",
  "GERONA",
  "GRANADA",
  "GUADALAJARA",
  "GUIPÚZCOA",
  "HUELVA",
  "HUESCA",
  "ISLAS BALEARES",
  "JAÉN",
  "LEÓN",
  "LÉRIDA",
  "LUGO",
  "MADRID",
  "MÁLAGA",
  "MURCIA",
  "NAVARRA",
  "ORENSE",
  "PALENCIA",
  "LAS PALMAS",
  "PONTEVEDRA",
  "LA RIOJA",
  "SALAMANCA",
  "SEGOVIA",
  "SEVILLA",
  "SORIA",
  "TARRAGONA",
  "SANTA CRUZ DE TENERIFE",
  "TERUEL",
  "TOLEDO",
  "VALENCIA",
  "VALLADOLID",
  "VIZCAYA",
  "ZAMORA",
  "ZARAGOZA",
];
