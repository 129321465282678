import { useAuth } from 'hooks'
import { Navigate, useLocation } from 'react-router-dom'
import { routes } from './routes'
import { auth } from 'configs/firebaseConfig'

export default function RequireAuth({ children }) {
  const { user } = useAuth()
  const location = useLocation()

  if (!auth.currentUser || !auth.currentUser.emailVerified) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={routes.sign_in} state={{ from: location }} replace />
  }

  if (!user) {
    return (
      <Navigate
        to={routes.missingUserData}
        state={{ from: location }}
        replace
      />
    )
  }

  return children
}
