import React from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import { Home, Personalcard, Notification, FolderOpen } from 'iconsax-react'
import { routes } from 'navigation/routes'
import { Group, Box, Text, Container, Indicator } from '@mantine/core'
import { useNotifications } from 'hooks'

export default function BottomBar() {
  const { notifications } = useNotifications()

  return (
    <Container
      size="xs"
      pos="fixed"
      bottom={0}
      left={0}
      right={0}
      p={0}
      sx={{ zIndex: 200 }}
    >
      <Group
        id="bottom-bar"
        bg="white"
        h={56}
        grow
        my={16}
        mx={8}
        px="sm"
        spacing="xs"
        sx={(theme) => ({
          borderRadius: 24,
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          border: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        <CustomLink to={routes.home} text="Inicio" icon={<Home />} />
        <CustomLink
          to={routes.certificates}
          text="Certificados"
          icon={<Personalcard />}
        />
        <CustomLink
          to={routes.myFolder}
          text="Mi carpeta"
          icon={<FolderOpen />}
        />
        <CustomLink
          to={routes.notifications}
          indicator={notifications?.notSeen?.length > 0}
          text="Avisos"
          icon={<Notification />}
        />
      </Group>
    </Container>
  )
}

function CustomLink({ to, text, icon, indicator }) {
  let resolved = useResolvedPath(to)
  let match = useMatch({ path: resolved.pathname, end: true })

  return (
    <Box
      component={Link}
      to={to}
      c={match ? 'brand' : 'gray.6'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
      }}
    >
      {icon && (
        <Box
          display="flex"
          sx={{
            transition: 'all 150ms ease-in-out',
            transform: !match ? 'translateY(10px)' : 'inherit',
          }}
        >
          {indicator ? (
            <Indicator
              display="inherit"
              size={12}
              offset={7}
              color="red"
              withBorder
            >
              {React.cloneElement(icon, {
                size: 24,
                variant: match ? 'Bulk' : 'Linear',
              })}
            </Indicator>
          ) : (
            React.cloneElement(icon, {
              size: 24,
              variant: match ? 'Bulk' : 'Linear',
            })
          )}
        </Box>
      )}
      <Text
        span
        fz="xs"
        sx={{
          transition: 'all 150ms ease-in-out',
          transform: match ? 'scale(1)' : 'scale(0) translateY(-16px)',
        }}
      >
        {text}
      </Text>
    </Box>
  )
}
