export const importantInfo = [
  {
    title: 'Compromisos',
    content: [
      'Cumplir las obligaciones propias del proceso de certificación, en particular acepta someterse a las actividades de evaluación por parte de CERTIFIQUE y a prestar la debida colaboración en las mismas.',
      'Cumplir con los requisitos para la certificación y proporcionar cualquier información necesaria para la evaluación.',
      'Dar información veraz y verificable en la solicitud de certificación.',
      'Dar trato respetuoso y digno a todo el personal de CERTIFIQUE con el que tenga contacto, tanto directo como indirecto.',
      'Seguir las indicaciones dadas por todo el personal de CERTIFIQUE.',
      'Llevar a cabo el proceso de certificación con total honestidad sin cometer fraude durante los exámenes.',
      'Guardar la respectiva confidencialidad de cada una de las pruebas.',
      'Cumplir con todas las normas de seguridad en el momento de las pruebas prácticas.',
      'Cuidar de las instalaciones, equipos y recursos suministrados para el proceso de certificación.',
      'Dar información oportuna, clara, veraz y completa de las condiciones de salud en el momento de las pruebas.',
      'Abonar las tarifas vigentes en el momento de la solicitud y en los plazos establecidos.',
      'Mantener actualizados los datos existentes en la entidad certificadora para poder comunicarse por correo ordinario, electrónico o telefónico.',
    ],
  },
  {
    title: 'El solicitante queda informado de lo siguiente',
    content: [
      'Las comunicaciones de CERTIFIQUE, se dirigirán a las direcciones de e-mail arriba indicadas',
      'Para poder desarrollar la actividad, es necesario, además de poseer esta certificación, dirigirse al órgano competente de su Comunidad Autónoma que es el responsable de la habilitación profesional.',
      'Si se producen cambios importantes en el esquema, se podrá determinar la necesidad de verificar por el método que se considere técnicamente adecuado, que la persona cumple los nuevos requisitos y si no sigue o no supera el proceso determinado por CERTIFIQUE para ello, podría derivarse en la suspensión o la retirada de la certificación.',
      'Durante el examen sólo podrá estar presente personal de CERTIFIQUE (examinador, vigilante y candidatos), dirección de CERTIFIQUE (de manera justificada), la autoridad reguladora y personal del organismo de acreditación (estos tres últimos en calidad de observadores).',
    ],
  },
  {
    title: 'Acuerdos con la firma de la solicitud',
    content: [
      'Con la cumplimentación y firma de dicha solicitud, el solicitante acuerda cumplir con los requisitos de la certificación, proporcionar toda información necesaria para la evaluación y conocer y aceptar las normas establecidas por CERTIFIQUE, al igual que los derechos y deberes de los solicitantes y personas certificadas. También declara conocer el procedimiento de certificación y las tasas, todo lo cual es público y puede descargarse de la página web de CERTIFIQUE (www.certifique.es), o solicitarlo en nuestras oficinas.',
    ],
  },
  {
    title: 'Clausula de protección de datos',
    content: [
      'El responsable del tratamiento de sus datos es CERTIFIQUE. Sus datos serán tratados con la finalidad de gestionar su solicitud y, en caso de otorgar su consentimiento, para el envío de comunicaciones por  vía electrónica. No se cederán datos a terceros, salvo obligación legal. Puede ejercer sus derechos de acceso, rectificación, supresión a los datos personales, así como otros derechos. Más información en www.certifique.es',
    ],
  },
]

export const authorization =
  'Autorizo a CERTIFIQUE para la publicación de mis datos (nombre, apellidos, DNI y número de certificado) en la web, para cumplir con la obligación de hacer público el estado de la certificación, según la Norma UNE-EN ISO/IEC 17024.'

export const communications =
  'Autorizo a CERTIFIQUE para el envío de comunicaciones.'
