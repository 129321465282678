import { useAuth, useNotifications } from 'hooks'
import { Calendar, IconButton, InstallAppAlert, LinkCard } from 'components'
import {
  Text,
  Box,
  Divider,
  Space,
  Flex,
  Stack,
  ThemeIcon,
  Group,
  Menu,
} from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { capitalize } from 'utils'
import {
  Call,
  Sms,
  Global,
  TableDocument,
  Refresh,
  Logout,
  Profile,
  DocumentText,
} from 'iconsax-react'
import { routes } from 'navigation/routes'
import { Certifique, Facebook, Linkedin } from 'components/CustomIcons'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

const appLinks = [
  {
    title: 'Condiciones de uso y privacidad',
    subtitle: 'Si sigues usando nuestra app, aceptas las condiciones de uso.',
    url: 'https://certifique.es/politica-de-privacidad',
  },
]

const interestLinks = [
  {
    title: 'Tarifas de certificación',
    subtitle: 'Tasas de examen a abonar en cada convocatoria.',
    url: 'https://certifique.es/tarifas',
  },
  {
    title: 'Certificaciones',
    subtitle: 'Certificados de personas disponibles actualmente.',
    url: 'https://certifique.es/personas-certificadas',
  },
]

export default function Home() {
  const [opened, setOpened] = useState(false)
  const navigate = useNavigate()
  const { user, signOut } = useAuth()
  const { removeSubscription } = useNotifications()

  useEffect(() => {
    return () => {
      setOpened(false)
    }
  }, [])

  const notAvailableMessage = () => {
    showNotification({
      title: '¡Renueva tu certificado!',
      message:
        'Ponte en contacto con nosotros al menos un mes antes de la fecha de caducidad de tu certificado.',
      color: 'blue',
    })
  }

  const handleSignOut = () => {
    removeSubscription().then(() => {
      signOut()
    })
  }

  return (
    <>
      <Box component="section" aria-label="Saludo" mb={24}>
        <Flex justify="space-between">
          <Box sx={{ flex: 1 }}>
            <Text fz="xl">{`¡Hola,  ${capitalize(user.name)}! 👋`}</Text>
            <Text fz="sm" color="dimmed">
              Nos alegra tenerte por aquí.
            </Text>
          </Box>

          <Menu
            shadow="md"
            width={200}
            radius="lg"
            position="bottom-end"
            opened={opened}
            onChange={setOpened}
            closeOnItemClick={false}
          >
            <Menu.Target>
              <IconButton radius="xl" size="xl" variant="light">
                <Profile />
              </IconButton>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                onClick={() => navigate(routes.myData)}
                icon={<DocumentText size={16} />}
              >
                Mis datos
              </Menu.Item>

              <Menu.Divider />

              <Menu.Item
                onClick={handleSignOut}
                color="red"
                icon={<Logout size={16} />}
              >
                Cerrar sesión
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </Box>
      <InstallAppAlert />
      <Box component="section" aria-label="Calendario" my={16}>
        <Text fw={500} mb={12}>
          Próximas convocatorias
        </Text>
        <Calendar height={300} />
      </Box>
      <Box component="section" aria-label="Trámites" my={16}>
        <Text fw={500} mb={12}>
          Trámites
        </Text>
        <LinkCard
          to={routes.request}
          text="Solicitud de certificación"
          icon={<TableDocument />}
        />
        <Space h="xs" />
        <LinkCard
          onClick={notAvailableMessage}
          // to={routes.maintenance}
          text="Mantenimiento y renovación"
          icon={<Refresh />}
        />
        <Divider my="lg" label="Para otros trámites" labelPosition="center" />
        <LinkCard
          to="mailto:tecnico@certifique.es"
          text="tecnico@certifique.es"
          target="_blank"
          rel="noreferrer"
          icon={<Sms />}
        />
        <Space h="sm" />
        <LinkCard
          to="tel:957477314"
          target="_blank"
          rel="noreferrer"
          text="957 47 73 14"
          icon={<Call />}
        />
        <Space h="sm" />
        <LinkCard
          to="https://certifique.es"
          target="_blank"
          rel="noreferrer"
          text="certifique.es"
          icon={<Global />}
        />
      </Box>
      <Box component="section" aria-label="Enlaces de interés" my={16}>
        <Text fw={500} mb={12}>
          Enlaces de interés
        </Text>
        <Stack spacing="xs">
          {interestLinks.map((item) => (
            <LinkCard
              key={item.title}
              to={item.url}
              text={item.title}
              subtitle={item.subtitle}
              target="_blank"
              rel="noreferrer"
            />
          ))}
        </Stack>
      </Box>
      <Box component="section" aria-label="Aplicación" my={16}>
        <Text fw={500} mb={12}>
          Acerca de la aplicación
        </Text>
        <Stack spacing="xs">
          <Flex gap={16}>
            <ThemeIcon radius="md" size="xl">
              <Certifique />
            </ThemeIcon>
            <Flex direction="column">
              <Text mb={8} inline>
                Certifique App
                <Text
                  span
                  inline
                  ml={8}
                  py={2}
                  px={10}
                  color="white"
                  bg="dark"
                  fz="xs"
                  sx={{
                    borderRadius: 44,
                  }}
                >{`v ${process.env.REACT_APP_VERSION}`}</Text>
              </Text>
              <Text c="dimmed" fz="sm">
                Con esta aplicación web oficial podrás tener acceso rápido,
                solicitar y renovar tus certificados.
              </Text>
            </Flex>
          </Flex>
          <Group
            position="center"
            mt={4}
            mb={16}
            mx="auto"
            spacing="xs"
            py={2}
            px={8}
            sx={{
              borderRadius: 44,
              boxShadow: '0px 1px 8px rgba(0,0,0,0.1)',
              maxWidth: 'fit-content',
            }}
          >
            <IconButton
              component="a"
              href={routes.linkedin}
              target="_blank"
              rel="noreferrer"
              radius="lg"
              color="blue.8"
              size="lg"
            >
              <Linkedin />
            </IconButton>
            <IconButton
              component="a"
              href={routes.facebook}
              target="_blank"
              rel="noreferrer"
              radius="lg"
              color="blue"
              size="lg"
            >
              <Facebook />
            </IconButton>
          </Group>
          {appLinks.map((item) => (
            <LinkCard
              key={item.title}
              url={item.url}
              text={item.title}
              subtitle={item.subtitle}
              target="_blank"
              rel="noreferrer"
            />
          ))}
        </Stack>
      </Box>
    </>
  )
}
