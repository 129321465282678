import { forwardRef } from 'react'
import {
  Button as MantineButton,
  createPolymorphicComponent,
  createStyles,
} from '@mantine/core'

const useStyles = createStyles(() => ({
  root: {
    transition: 'all 200ms ease',
    '&:active': { transform: 'scale(0.98)' },
  },
}))

const _Button = forwardRef(({ text, ...others }, ref) => {
  const { classes } = useStyles()
  return (
    <MantineButton
      classNames={{
        root: classes.root,
      }}
      radius="md"
      ref={ref}
      {...others}
    >
      {text}
    </MantineButton>
  )
})

_Button.displayName = 'Button'

const Button = createPolymorphicComponent(_Button)

export default Button
