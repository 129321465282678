import {
  applyActionCode,
  checkActionCode,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  signOut,
} from 'firebase/auth'

export function handleResetPassword(auth, actionCode, continueUrl, lang) {
  return {
    verify: () =>
      verifyPasswordResetCode(auth, actionCode)
        .then((email) => ({ success: true, email, error: null }))
        .catch((error) => ({ success: false, error, email: null })),

    reset: (email, newPassword) =>
      confirmPasswordReset(auth, actionCode, newPassword)
        .then(async () => {
          try {
            await signOut(auth)
            return { success: true }
          } catch (error) {
            return { success: false, error }
          }
        })
        .catch((error) => ({ success: false, error })),
  }
}

export async function handleRecoverEmail(auth, actionCode, lang) {
  try {
    const info = await checkActionCode(auth, actionCode)
    const email = info['data']['email']
    await applyActionCode(auth, actionCode)
    await sendPasswordResetEmail(auth, email)
    return { success: true, error: null, email }
  } catch (error) {
    return { success: false, error }
  }
}

export async function handleVerifyEmail(auth, actionCode, continueUrl, lang) {
  try {
    await applyActionCode(auth, actionCode)
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}

export function parseFirebaseDate(date) {
  if (!date) return null

  if (typeof date === 'object') {
    if (date instanceof Date) return date

    const dateObject = new Date(
      date.seconds * 1000 + date.nanoseconds / 1000000
    )
    return dateObject
  }

  return date
}
