import { showNotification } from '@mantine/notifications'
import { useAuth, useFirebase } from 'hooks'
import { useState } from 'react'
import { DEBUG } from 'utils'

export default function useInvoices() {
  const [loading, setLoading] = useState(false)
  const { user: authUser } = useAuth()
  const {
    addInvoice,
    removeInvoice,
    invoicesColl,
    getAllDocuments,
    categoriesColl,
  } = useFirebase()

  async function newInvoice({ userDocId, values }) {
    if (!userDocId) return
    setLoading(true)
    try {
      const invoice = await addInvoice({
        userDocId: userDocId,
        uid: authUser.uid,
        data: values,
      })
      setLoading(false)
      return invoice
    } catch (error) {
      setLoading(false)
      if (error.name === 'TooManyDocumentsError') {
        showNotification({
          message: error.message,
          color: 'red',
        })
      } else {
        showNotification({
          message: 'Ha habido un error al subir el archivo.',
          color: 'red',
        })
      }

      throw error
    }
  }

  async function getCategories() {
    const categoriesDocs = await getAllDocuments({
      coll: categoriesColl,
    })
    return categoriesDocs.map((category) => category.data)
  }

  async function getInvoices(userDocId) {
    if (!userDocId) return
    setLoading(true)
    try {
      const categories = await getCategories()

      let invoices = await getAllDocuments({
        coll: invoicesColl(userDocId),
        orderKey: 'date',
        sort: 'desc',
      })

      invoices = invoices
        .map(({ docId, data }) => ({
          docId,
          category: data.category,
          name: data.file.name,
          url: data.file.url,
          type: data.file.type,
          date: data.date.toDate(),
        }))
        .reduce((prev, curr) => {
          const category = categories.find((item) => item.id === curr.category)

          let index = prev.findIndex(
            (obj) => obj.category.value === curr.category
          )

          if (index === -1) {
            prev.push({
              category: { value: category.id, label: category.code },
              items: [],
            })
            index = prev.length - 1
          }

          prev[index].items.push(curr)

          return prev
        }, [])
      setLoading(false)
      return invoices
    } catch (error) {
      if (DEBUG) {
        console.log({ error })
      }
      setLoading(false)
      showNotification({
        message: 'Ha habido un error al obtener los datos',
        color: 'red',
      })
      throw error
    }
  }

  async function deleteInvoice({ userDocId, invoice }) {
    if (!userDocId) return
    setLoading(true)
    try {
      removeInvoice({
        uid: authUser.uid,
        userDocId,
        fileName: invoice.name,
        invoiceDocId: invoice.docId,
      })
      setLoading(false)
    } catch (error) {
      if (DEBUG) {
        console.log({ error })
      }
      setLoading(false)
      showNotification({
        message: 'Ha habido un error al eliminar el archivo',
        color: 'red',
      })
      throw error
    }
  }

  return { newInvoice, getInvoices, deleteInvoice, getCategories, loading }
}
