export const Certifique = (props) => (
  <svg
    width={props?.size ?? '24'}
    height={props?.size ?? '24'}
    viewBox="0 0 24 24"
    fill="currentcolor">
    <path d="M19.4924 18.1719L15.9405 14.3219L15.2079 15.1312C14.2311 16.225 12.8325 16.8594 11.3895 16.8594C8.52573 16.8594 6.21695 14.4531 6.21695 11.5C6.21695 8.54688 8.54793 6.14062 11.3895 6.14062C14.0313 6.14062 16.2957 8.2625 16.5398 10.9969L16.6287 11.8719H21.8234L21.7568 10.8438C21.4238 5.33125 16.8729 1 11.3895 1C5.66196 1 1 5.70312 1 11.5C1 17.2969 5.66196 22 11.3895 22H23L19.4924 18.1719Z" />
  </svg>
)

export const Linkedin = (props) => (
  <svg
    width={props?.size ?? '24'}
    height={props?.size ?? '24'}
    fill="currentcolor">
    <path d="M0 1.719C0 0.7695 0.789 0 1.7625 0H22.2375C23.211 0 24 0.7695 24 1.719V22.281C24 23.2305 23.211 24 22.2375 24H1.7625C0.789 24 0 23.2305 0 22.281V1.719ZM7.4145 20.091V9.2535H3.813V20.091H7.4145ZM5.6145 7.773C6.87 7.773 7.6515 6.942 7.6515 5.901C7.629 4.8375 6.8715 4.029 5.6385 4.029C4.4055 4.029 3.6 4.839 3.6 5.901C3.6 6.942 4.3815 7.773 5.5905 7.773H5.6145V7.773ZM12.9765 20.091V14.0385C12.9765 13.7145 13.0005 13.3905 13.0965 13.1595C13.356 12.513 13.9485 11.8425 14.9445 11.8425C16.248 11.8425 16.7685 12.8355 16.7685 14.2935V20.091H20.37V13.875C20.37 10.545 18.594 8.997 16.224 8.997C14.313 8.997 13.4565 10.047 12.9765 10.7865V10.824H12.9525C12.9605 10.8115 12.9685 10.799 12.9765 10.7865V9.2535H9.3765C9.4215 10.2705 9.3765 20.091 9.3765 20.091H12.9765Z" />
  </svg>
)

export const Facebook = (props) => (
  <svg
    width={props?.size ?? '24'}
    height={props?.size ?? '24'}
    viewBox="0 0 24 24"
    fill="currentcolor">
    <path d="M23.9991 12.073C23.9991 5.40399 18.6261 -0.00201416 11.9991 -0.00201416C5.36909 -0.00051416 -0.00390625 5.40399 -0.00390625 12.0745C-0.00390625 18.1 4.38509 23.095 10.1211 24.001V15.5635H7.07609V12.0745H10.1241V9.41199C10.1241 6.38649 11.9166 4.71549 14.6571 4.71549C15.9711 4.71549 17.3436 4.95099 17.3436 4.95099V7.92099H15.8301C14.3406 7.92099 13.8756 8.85249 13.8756 9.80799V12.073H17.2026L16.6716 15.562H13.8741V23.9995C19.6101 23.0935 23.9991 18.0985 23.9991 12.073Z" />
  </svg>
)
