import { useNavigate } from 'react-router-dom'
import { IconButton } from 'components'
import { Container, Text } from '@mantine/core'
import { ArrowLeft2 } from 'iconsax-react'

export default function TopBar({ title, onBack }) {
  let navigate = useNavigate()

  function goBack() {
    navigate(-1)
  }

  return (
    <Container
      id="top-bar"
      size="xs"
      pos="fixed"
      top={0}
      left={0}
      right={0}
      h={56}
      bg="white"
      sx={{
        display: 'flex',
        gap: 16,
        alignItems: 'center',
        zIndex: 50,
        borderBottom: '1px solid rgba(0,0,0,0.05)'
      }}>
      <IconButton
        onClick={onBack || goBack}
        radius="xl"
        size="xl"
        variant="light">
        <ArrowLeft2 variant="Linear" size={20} />
      </IconButton>
      <Text fz="lg">{title}</Text>
    </Container>
  )
}
