import { Container, Divider } from '@mantine/core'
import { TopBar, UserDataForm } from 'components'
import { AuthForm } from 'components/forms/AuthForm'
import { useNavigate } from 'react-router-dom'

export default function MyData() {
  const navigate = useNavigate()

  const onUpdate = () => {
    navigate(0)
  }

  return (
    <>
      <TopBar title="Mis datos" />
      <Container py={68}>
        <UserDataForm onSuccess={onUpdate} disabledItems={['id']} />
        <Divider
          my="lg"
          label="Datos inicio de sesión"
          labelPosition="center"
        />
        <AuthForm />
      </Container>
    </>
  )
}
