export default function darkenColor(hexColor, percentage) {
  // Convert the hex color to RGB
  var r = parseInt(hexColor.slice(1, 3), 16);
  var g = parseInt(hexColor.slice(3, 5), 16);
  var b = parseInt(hexColor.slice(5, 7), 16);

  // Calculate the new RGB values
  r = Math.round(r * (1 - percentage / 100));
  g = Math.round(g * (1 - percentage / 100));
  b = Math.round(b * (1 - percentage / 100));

  // Convert the RGB values back to hex
  var hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

  return hex;
}
