import { useEffect } from 'react'
import { useServiceWorker } from 'hooks'
import { useDisclosure } from '@mantine/hooks'
import { Dialog, Flex, Text } from '@mantine/core'
import { Button } from 'components'

export default function UpdateAvailable() {
  const [opened, { toggle, close }] = useDisclosure(false)
  const { isUpdateAvailable, updateAssets } = useServiceWorker()

  useEffect(() => {
    if (isUpdateAvailable) {
      toggle()
    }
  }, [isUpdateAvailable, updateAssets])

  return (
    <Dialog
      opened={opened}
      withCloseButton
      onClose={close}
      size="100%"
      radius="lg"
      maw={490}
      mx="auto"
      position={{ bottom: 12, left: 12, right: 12 }}
    >
      <Flex gap="xl" mb="sm" align="center">
        <Text size={42}>🎉</Text>
        <div>
          <Text size="sm" weight={500} mr="xl">
            ¡Hay una actualización!
          </Text>
          <Text size="sm" weight={500} mr="xl" c="dimmed">
            Actualiza ahora para disponer de las últimas novedades y mejoras.
          </Text>
        </div>
      </Flex>

      <Button fullWidth onClick={() => updateAssets()} text="Actualizar" />
    </Dialog>
  )
}
