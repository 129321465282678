import { Grid, Modal, PasswordInput, Text, TextInput } from '@mantine/core'
import { isEmail, isNotEmpty, matchesField, useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { Button } from 'components'
import { useAuth, useFirebase } from 'hooks'
import { useState } from 'react'
import { DEBUG } from 'utils'

export function AuthForm() {
  const [opened, { open, close }] = useDisclosure(false)
  const [updatingEmail, setUpdatingEmail] = useState(false)
  const { user, updateUserEmail, signOut } = useAuth()
  const { updateUser } = useFirebase()

  const form = useForm({
    initialValues: {
      email: '',
      repeatEmail: '',
      password: '',
    },
    validate: {
      email: isEmail('Parece que el email no tiene el formato correcto.'),
      repeatEmail: matchesField('email', 'El email no coincide.'),
      password: isNotEmpty('Debes ingresar tu contraseña actual.'),
    },
  })

  const handleSubmit = async (values) => {
    setUpdatingEmail(true)

    try {
      const updateUserEmailPromise = updateUserEmail({
        newEmail: values.email,
        password: values.password,
      })
      const updateUserPromise = updateUser({
        data: { email: values.email },
        docId: user.userDocId,
      })

      await Promise.all([updateUserEmailPromise, updateUserPromise])

      showNotification({
        message: 'El correo electrónico se ha actualizado con éxito.',
        color: 'green',
      })
      signOut()
    } catch (error) {
      if (DEBUG) {
        console.log({ error })
      }
      if (error.code === 'auth/wrong-password') {
        form.setFieldError('password', 'La contraseña no es correcta.')
      }
    } finally {
      setUpdatingEmail(false)
    }
  }

  return (
    <>
      <div>
        <Grid>
          <Grid.Col>
            <TextInput
              required
              label="Correo electrónico"
              type="email"
              withAsterisk={false}
              disabled
              defaultValue={user?.email}
            />
          </Grid.Col>
          <Grid.Col>
            <Button
              text="Modificar correo electrónico"
              fullWidth
              variant="light"
              onClick={open}
            />
          </Grid.Col>

          {/* <Grid.Col>
            <PasswordInput
              required
              label="Contraseña"
              withAsterisk={false}
              disabled
              defaultValue="******"
            />
            <Space h="xs" />
          </Grid.Col> */}
        </Grid>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        title="Modificar datos de acceso"
        centered
        radius="lg"
      >
        <Text size="sm" mb={12}>
          Las sesión se cerrará automáticamente. Deberás confirmar el correo e
          iniciar sesión de nuevo.
        </Text>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Grid>
            <Grid.Col>
              <TextInput
                required
                label="Nuevo correo electrónico"
                type="email"
                withAsterisk
                disabled={updatingEmail}
                {...form.getInputProps('email')}
              />
            </Grid.Col>
            <Grid.Col>
              <TextInput
                required
                label="Confirmar correo"
                type="email"
                withAsterisk
                disabled={updatingEmail}
                {...form.getInputProps('repeatEmail')}
              />
            </Grid.Col>
            <Grid.Col>
              <PasswordInput
                required
                label="Contraseña actual"
                description="Para poder actualizar tu email, debes introducir tu contraseña actual."
                withAsterisk
                disabled={updatingEmail}
                {...form.getInputProps('password')}
              />
            </Grid.Col>
            <Grid.Col>
              <Button
                text="Actualizar email"
                type="submit"
                fullWidth
                loading={updatingEmail}
                disabled={!form.isDirty()}
              />
            </Grid.Col>
          </Grid>
        </form>
      </Modal>
    </>
  )
}
