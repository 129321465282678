import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'
import { getAnalytics } from 'firebase/analytics'
import { initializeFirestore, memoryLocalCache } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}
// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const messaging = getMessaging(app)
export const analytics = getAnalytics(app)
export const db = initializeFirestore(app, { localCache: memoryLocalCache() })
export const auth = getAuth(app)
export const storage = getStorage(app)
