import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SignaturePad from 'react-signature-canvas'
import { TopBar, Button, FilesInput } from 'components'
import {
  Box,
  Container,
  Grid,
  Radio,
  Select,
  Space,
  Text,
  TextInput,
  Collapse,
  Input,
  ScrollArea,
  Checkbox,
  Paper,
  ActionIcon,
  Tooltip,
  Textarea,
  Alert,
} from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { Broom, InfoCircle } from 'iconsax-react'
import { useAuth, useCategories, useFirebase } from 'hooks'
import { authorization, importantInfo, communications } from 'constants/texts'
import useCenters from 'hooks/useCenters'
import { base64ToImage } from 'utils/base64ToImage'
import { dateFormat, DEBUG } from 'utils'

// const getYears = () => {
//   var max = new Date().getFullYear()
//   var min = max - 5
//   var years = []

//   for (var i = max; i >= min; i--) {
//     years.push(String(i))
//   }
//   return years
// }

const initialValues = {
  certification: {
    type: '', // Category code
    exam: 'both', //th, p, both
  },
  previewExams: {
    exam: '', //th, p
    year: '',
    record: '',
  },
  files: [],
  authorizations: {
    data: false, // Authorization for the publication of data
    communications: false, // Authorization for communications
  },
  date: new Date(),
  sign: '',
  billData: {
    active: false,
    name: '',
    company: '',
    address: '',
    nif: '',
    email: '',
  },
  center: '',
  exam_date: '',
  notes: '',
}

const medicalDocumentCategories = ['OGTC']

const obligatoryField = 'Campo obligatorio'
const examsTypes = [
  {
    value: 'both',
    label: 'Teórico y práctico',
  },
  {
    value: 'th',
    label: 'Teórico',
  },
  {
    value: 'p',
    label: 'Práctico',
  },
]
// const previewExam = [
//   {
//     value: 'both',
//     label: 'Prueba teórica y práctica',
//   },
//   {
//     value: 'th',
//     label: 'Prueba teórica',
//   },
//   {
//     value: 'p',
//     label: 'Prueba práctica',
//   },
// ]

export default function RequestForm() {
  const sigPad = useRef(null)
  let { user } = useAuth()
  const { categories } = useCategories()
  const { centers } = useCenters()
  const [loading, setLoading] = useState(false)
  const { addRequest, getCalendarEvents } = useFirebase()
  const [events, setEvents] = useState([])
  let navigate = useNavigate()

  useEffect(() => {
    getCalendarEvents().then((resp) => {
      const flatEvents = Object.values(resp).flat()
      setEvents(flatEvents)
    })
  }, [])

  function clearSignature() {
    sigPad.current.clear()
    form.setFieldValue('sign', '')
  }

  async function handleSignatureChanges(e) {
    e.preventDefault()
    const trimmedDataURL = sigPad.current.toDataURL('image/png')
    const imageFile = await base64ToImage(trimmedDataURL, 'signature.png')
    form.setFieldValue('sign', imageFile)
  }

  // function clearPrevExams() {
  //   form.setFieldValue('previewExams', initialValues.previewExams)
  // }

  function handleSubmit(values) {
    setLoading(true)
    const center = centers.find((c) => c.value === values.center).label
    const data = { ...values, uid: user.uid, state: 'sent', center }

    addRequest({ data })
      .then(() => {
        showNotification({
          message:
            'Solicitud enviada con éxito. Nos pondremos en contacto lo antes posible. ¡Gracias!',
          color: 'green',
        })
        navigate('/', { replace: true })
      })
      .catch((err) => {
        if (DEBUG) {
          console.log(err)
        }
        showNotification({
          message:
            'Upps! Ha habido un error. Por favor prueba más tarde. Si el error persiste, contacta con nosotros.',
          color: 'red',
        })
        setLoading(false)
      })
  }

  const form = useForm({
    initialValues,
    validate: {
      certification: {
        type: (value) =>
          value === '' ? 'Debes seleccionar una certificación.' : null,
      },
      billData: {
        name: (value, values) =>
          values.billData.active && value === '' ? obligatoryField : null,
        company: (value, values) =>
          values.billData.active && value === '' ? obligatoryField : null,
        address: (value, values) =>
          values.billData.active && value === '' ? obligatoryField : null,
        nif: (value, values) =>
          values.billData.active && value === '' ? obligatoryField : null,
        email: (value, values) =>
          values.billData.active && value === '' ? obligatoryField : null,
      },
      authorizations: {
        data: (value) =>
          value === false ? 'Tienes que marcar la casilla.' : null,
        communications: (value) =>
          value === false ? 'Tienes que marcar la casilla.' : null,
      },
      sign: (value) => (value === '' ? 'Tienes que firmar en el cuadro' : null),
      previewExams: {
        year: (value, values) =>
          Boolean(values.previewExams.exam) && value === ''
            ? obligatoryField
            : null,
        record: (value, values) =>
          Boolean(values.previewExams.exam) && value === ''
            ? obligatoryField
            : null,
      },
      center: isNotEmpty('Este campo es obligatorio.'),
      exam_date: isNotEmpty('Este campo es obligatorio.'),
    },
  })

  const loadingData = categories.length === 0 || centers.length === 0
  const centerEvents = events.filter((e) => e.centerId === form.values.center)
  const examDateOptions = centerEvents.map((e) => dateFormat(e.date, 'long'))

  return (
    <>
      <TopBar title="Solicitud de certificación" />

      <Container pt={68} pb={24}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Grid>
            <Grid.Col>
              <Alert
                icon={<InfoCircle size="1rem" />}
                title="IMPORTANTE"
                radius="md"
                color="orange"
              >
                <Text fw={500}>
                  - Una solicitud mal cumplimentada será rechazada.
                </Text>
                <Text fw={500}>
                  - La solicitud debe enviarse con al menos 7 días de antelación
                  a la fecha de examen. En caso contrario, será rechazada.
                </Text>
              </Alert>
            </Grid.Col>
            {/* TIPO DE CERTIFICADO */}
            <Grid.Col>
              <Select
                required
                disabled={loadingData}
                label="Tipo de certificación"
                description="Seleccione la certificación a la que se presenta."
                placeholder={
                  loadingData ? 'Obteniendo datos...' : 'Seleccione uno'
                }
                data={categories}
                {...form.getInputProps('certification.type')}
              />
            </Grid.Col>

            {/* TIPO DE EXAMEN */}
            <Grid.Col>
              <Radio.Group
                required
                label="Tipo de examen al que se presenta"
                description="Seleccione el tipo de examen al que se quiere presentar."
                name="certification"
                sx={{
                  '& .mantine-RadioGroup-description': {
                    marginBottom: 16,
                  },
                  '& .mantine-Radio-root': {
                    marginTop: 8,
                  },
                }}
                {...form.getInputProps('certification.exam')}
              >
                {examsTypes.map((item) => (
                  <Radio
                    key={item.value}
                    value={item.value}
                    label={item.label}
                  />
                ))}
              </Radio.Group>
            </Grid.Col>

            {/* PRUEBAS REALIZADAS CON ANTERIORIDAD */}
            {/* <Grid.Col>
              <Radio.Group
                label="Pruebas realizadas con anterioridad"
                description="Si ha realizado pruebas con anterioridad, indique la parte que
              tenga aprobada."
                name="previewExams"
                orientation="vertical"
                sx={{
                  '& .mantine-RadioGroup-description': {
                    marginBottom: 16,
                  },
                  '& .mantine-Radio-root': {
                    marginTop: 8,
                  },
                }}
                {...form.getInputProps('previewExams.exam')}
              >
                {previewExam.map((item) => (
                  <Radio
                    key={item.value}
                    value={item.value}
                    label={item.label}
                  />
                ))}
              </Radio.Group>
              <Collapse in={Boolean(form.values.previewExams.exam)}>
                <Space h="md" />
                <Select
                  label="Año"
                  description="Año en que se examinó."
                  data={getYears()}
                  {...form.getInputProps('previewExams.year')}
                />
                <Space h="xs" />
                <TextInput
                  label="Número de expediente"
                  description="El número de expediente aparece en el informe de resultados."
                  {...form.getInputProps('previewExams.record')}
                />
                <Space h="xs" />
                <Flex justify="flex-end">
                  <Button
                    text="Deshacer"
                    variant="light"
                    onClick={clearPrevExams}
                  />
                </Flex>
              </Collapse>
            </Grid.Col> */}

            {/* DOCUMENTOS */}
            <Grid.Col>
              <FilesInput
                id="files"
                required
                placeholder="Seleccionar archivos"
                label="Documentos a aportar"
                description={
                  <>
                    <Text mb={6}>
                      * DNI, carné de conducir, pasaporte o permiso de trabajo o
                      residencia en vigor, NIE o cualquier documento oficial que
                      permita acreditar la identidad del solicitante El
                      documento debe incluir foto del solicitante.
                    </Text>
                    <Text mb={6}>
                      * Copia del diploma o certificado del curso realizado y de
                      acuerdo al temario reflejado en el PGC-03 “Competencia
                      técnica de los técnicos certificados”, en función a la
                      categoría solicitada.
                    </Text>
                    <Text mb={8}>* Justificante del pago de las tasas.</Text>
                    {medicalDocumentCategories.includes(
                      form.values.certification.type
                    ) && (
                      <Text mb={8}>* Examen médico sobre agudeza visual.</Text>
                    )}
                    <Text>
                      ¿Tiene alguna necesidad especial a tener en cuenta para el
                      proceso de evaluación? Indique con la suficiente
                      antelación el tipo de necesidad y el grado en el que se
                      encuentra reconocida.
                    </Text>
                  </>
                }
                accept="image/png,image/jpeg,application/pdf"
                maxFiles={8}
                maxSize={3}
                form={form}
              />
            </Grid.Col>

            {/* CENTER */}
            <Grid.Col>
              <Select
                id="center"
                required
                disabled={loadingData}
                label="Lugar en el que se presenta a examen"
                description="Seleccione el centro de examen en el que se examinará."
                placeholder={
                  loadingData ? 'Obteniendo datos...' : 'Seleccione uno'
                }
                data={centers}
                {...form.getInputProps('center')}
                onChange={(value) => {
                  form.setFieldValue('exam_date', '')
                  form.setFieldValue('center', value)
                }}
              />
            </Grid.Col>

            <Grid.Col>
              <Select
                id="exam_date"
                descriptionProps={{
                  'data-empty':
                    form.values.center && examDateOptions.length === 0
                      ? ''
                      : undefined,
                  sx: (theme) => ({
                    '&[data-empty]': {
                      color: theme.colors.orange[7],
                      fontWeight: 600,
                    },
                  }),
                }}
                required
                disabled={
                  loadingData ||
                  !form.values.center ||
                  examDateOptions.length === 0
                }
                label="Fecha de examen"
                description={
                  form.values.center && examDateOptions.length === 0
                    ? 'No hay fechas disponibles para el centro seleccionado.'
                    : 'Seleccione una fecha disponible para examinarse.'
                }
                placeholder={
                  loadingData ? 'Obteniendo datos...' : 'Seleccione una fecha'
                }
                data={examDateOptions}
                {...form.getInputProps('exam_date')}
              />
            </Grid.Col>

            {/* NOTES */}
            <Grid.Col>
              <Textarea
                id="notes"
                label="Otras indicaciones"
                description="Indique cualquier otra indicación de importancia que quiera hacernos saber."
                autosize
                minRows={2}
                maxRows={4}
                {...form.getInputProps('notes')}
              />
            </Grid.Col>

            {/* AUTORIZACIÓN*/}
            <Grid.Col>
              <Input.Wrapper
                required
                label="Información importante"
                description="Debe leer y autorizar para poder enviar la solicitud."
              >
                <ScrollArea
                  type="always"
                  px={10}
                  mt={12}
                  sx={(theme) => ({
                    height: 350,
                    borderRadius: 12,
                    border: `1px solid ${theme.colors.gray[4]}`,
                  })}
                  scrollbarSize={4}
                >
                  {importantInfo.map(({ title, content }) => (
                    <Box key={title} my={8}>
                      <Text fw={500} tt="uppercase" mt={12} mb={4}>
                        {title}
                      </Text>
                      {content.map((item, inx) => (
                        <Text key={`item-${inx}`} mb={4}>
                          <Text span pr={4}>
                            •
                          </Text>
                          {item}
                        </Text>
                      ))}
                    </Box>
                  ))}
                </ScrollArea>
              </Input.Wrapper>
              <Space h={12} />
              <Checkbox
                required
                label={authorization}
                styles={{ label: { fontWeight: 500 } }}
                {...form.getInputProps('authorizations.data', {
                  type: 'checkbox',
                })}
              />
            </Grid.Col>

            {/* INFORMACIÓN DE FACTURACIÓN*/}
            <Grid.Col py={18}>
              <Input.Wrapper label="Datos de facturación">
                <Checkbox
                  label="Marque esta casilla si los datos de facturación difieren de los del solicitante."
                  {...form.getInputProps('billData.active', {
                    type: 'checkbox',
                  })}
                />
              </Input.Wrapper>
              <Collapse in={Boolean(form.values.billData.active)}>
                <Space h="md" />
                <TextInput
                  required={Boolean(form.values.billData.active)}
                  placeholder="Nombre y apellidos"
                  {...form.getInputProps('billData.name')}
                />
                <Space h="xs" />
                <TextInput
                  required={Boolean(form.values.billData.active)}
                  placeholder="Empresa"
                  {...form.getInputProps('billData.company')}
                />
                <Space h="xs" />
                <TextInput
                  required={Boolean(form.values.billData.active)}
                  placeholder="Dirección y provincia"
                  {...form.getInputProps('billData.address')}
                />
                <Space h="xs" />
                <TextInput
                  required={Boolean(form.values.billData.active)}
                  placeholder="NIF"
                  {...form.getInputProps('billData.nif')}
                />
                <Space h="xs" />
                <TextInput
                  required={Boolean(form.values.billData.active)}
                  placeholder="Correo electrónico"
                  type="email"
                  {...form.getInputProps('billData.email')}
                />
              </Collapse>
            </Grid.Col>

            {/* FIRMA*/}
            <Grid.Col>
              <Input.Wrapper
                required
                label="Firma del solicitante"
                description="Firma en el siguiente cuadro:"
                {...form.getInputProps('sign')}
              >
                <Paper
                  mt={12}
                  h={250}
                  withBorder
                  radius="lg"
                  sx={{ position: 'relative' }}
                >
                  <Box sx={{ position: 'absolute', top: 12, right: 12 }}>
                    <Tooltip label="Limpiar">
                      <ActionIcon onClick={clearSignature}>
                        <Broom />
                      </ActionIcon>
                    </Tooltip>
                  </Box>
                  <SignaturePad
                    canvasProps={{ style: { width: '100%', height: '100%' } }}
                    ref={sigPad}
                    onEnd={handleSignatureChanges}
                  />
                </Paper>
              </Input.Wrapper>
            </Grid.Col>

            {/* AUTORIZACIÓN COMUNICACIÓN*/}
            <Grid.Col>
              <Checkbox
                required
                label={communications}
                styles={{ label: { fontWeight: 500 } }}
                {...form.getInputProps('authorizations.communications', {
                  type: 'checkbox',
                })}
              />
            </Grid.Col>

            <Grid.Col>
              <Space h="sm" />
              <Button
                loading={loading}
                fullWidth
                text="Enviar solicitud"
                type="submit"
              />
            </Grid.Col>
          </Grid>
        </form>
      </Container>
    </>
  )
}
