import { Fragment, useEffect, useReducer } from 'react'
import {
  Stack,
  Group,
  Text,
  Box,
  Flex,
  ScrollArea,
  Skeleton,
  Center,
  Divider,
} from '@mantine/core'
import { useFirebase } from 'hooks'
import { dateFormat } from 'utils'
import { Calendar2, Location } from 'iconsax-react'

export default function Calendar({ height }) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { loading: true, events: {}, error: null }
  )

  const { getCalendarEvents } = useFirebase()

  useEffect(() => {
    getCalendarEvents()
      .then((resp) => {
        // console.log({ resp })
        setState({ events: resp, loading: false })
      })
      .catch((error) => {
        // console.log({ error })
        setState({ error, loading: false })
      })
  }, [])

  return (
    <ScrollArea
      bg="gray.2"
      style={{ height, borderRadius: 12 }}
      scrollbarSize={4}
    >
      <Stack spacing="xs" m={8}>
        {state.loading ? (
          new Array(3)
            .fill('')
            .map((it, idx) => <EventsItem key={idx} events={[]} />)
        ) : (
          <>
            {Object.keys(state.events).length > 0 ? (
              Object.entries(state.events).map(([date, events]) => (
                <EventsItem key={date} date={date} events={events} />
              ))
            ) : (
              <Center mih={height - 8 * 2}>
                <Stack align="center">
                  <Calendar2 size={48} variant="Bulk" />
                  <Text m={8} align="center">
                    Actualmente no hay ninguna convocatoria programada
                  </Text>
                </Stack>
              </Center>
            )}
          </>
        )}
      </Stack>
    </ScrollArea>
  )
}

const EventsItem = ({ date, events }) => {
  return (
    <Group
      position="apart"
      p="xs"
      bg="white"
      sx={{
        borderRadius: 8,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          gap: 24,
        }}
      >
        {date ? (
          <Stack
            justify="center"
            align="center"
            sx={{ alignSelf: 'flex-start' }}
            spacing={0}
          >
            <Text color="dimmed" fz="xs" transform="uppercase">
              {dateFormat(date, 'MM')}
            </Text>
            <Text color="brand" fz="lg" fw={700} lh={1}>
              {dateFormat(date, 'dd')}
            </Text>
            <Text fz="xs">{dateFormat(date, 'DD')}</Text>
          </Stack>
        ) : (
          <Stack
            justify="center"
            align="center"
            sx={{ alignSelf: 'flex-start' }}
            spacing={4}
          >
            <Skeleton height={16} radius="xs" width={20} />
            <Skeleton height={16} radius="xs" width={20} />
            <Skeleton height={16} radius="xs" width={20} />
          </Stack>
        )}

        <Box sx={{ flex: 1 }}>
          <Stack spacing={0}>
            {events.length === 0 && (
              <>
                <Skeleton height={16} radius="xs" width="100%" mb={4} />
                <Skeleton height={14} radius="xs" width="80%" mb={4} />
                <Skeleton height={14} radius="xs" width="80%" />
              </>
            )}
            {events.map((event, index) => (
              <Fragment key={event.id}>
                <Event {...event} />
                {index !== events.length - 1 && <Divider my="xs" />}
              </Fragment>
            ))}
          </Stack>
        </Box>
      </Box>
    </Group>
  )
}

const Event = ({ title, description, center }) => {
  return (
    <Box>
      <Text>{title.toUpperCase()}</Text>
      <Text fz="xs">{description}</Text>
      <Flex gap={6} align="start" sx={{ color: '#828282' }}>
        <Location
          size={16}
          variant="Linear"
          style={{ flexShrink: 0, color: 'currentColor' }}
        />
        <Text fz="xs">{center}</Text>
      </Flex>
    </Box>
  )
}
