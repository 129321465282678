import {
  Alert,
  Checkbox,
  Grid,
  PasswordInput,
  Space,
  TextInput,
} from '@mantine/core'
import { Button } from 'components'
import { useAuth } from 'hooks'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { hasLength, isEmail, matchesField, useForm } from '@mantine/form'
import { routes } from 'navigation/routes'

export default function SignUpForm() {
  const { registering, signup } = useAuth()
  const [error, setError] = useState(false)
  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      repeatPassword: '',
      policy: false,
    },
    validate: {
      email: isEmail('El formato del correo electrónico es incorrecto.'),
      password: hasLength(
        { min: 6, max: 18 },
        'La contraseña tiene que tener mínimo 6 caracteres y máximo 18.'
      ),
      repeatPassword: matchesField('password', 'Las contraseñas no coinciden.'),
      policy: (value) =>
        !value
          ? 'Debes aceptar las condiciones de uso para poder usar nuestra app.'
          : null,
    },
  })

  const handleSubmit = async (values) => {
    const { error } = await signup(values)

    if (error) {
      return setError(error.message)
    }

    return navigate(routes.emailSent, { replace: true })
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid>
        <Grid.Col>
          <TextInput
            required
            label="Correo electrónico"
            type="email"
            withAsterisk={false}
            disabled={registering}
            {...form.getInputProps('email')}
          />
        </Grid.Col>
        <Grid.Col>
          <PasswordInput
            required
            label="Contraseña"
            withAsterisk={false}
            disabled={registering}
            {...form.getInputProps('password')}
          />
        </Grid.Col>
        <Grid.Col>
          <PasswordInput
            required
            label="Confirma la contraseña"
            withAsterisk={false}
            disabled={registering}
            {...form.getInputProps('repeatPassword')}
          />
          <Space h="xs" />
        </Grid.Col>
        <Grid.Col>
          <Checkbox
            required
            label={
              <>
                He leido y acepto las{' '}
                <a href="https://certifique.es/politica-de-privacidad">
                  Condiciones de uso y Política de Privacidad
                </a>
                .
              </>
            }
            styles={{ label: { fontWeight: 500 } }}
            disabled={registering}
            {...form.getInputProps('policy', {
              type: 'checkbox',
            })}
          />
        </Grid.Col>
        {error && (
          <Grid.Col>
            <Alert
              variant="light"
              color="red"
              my={12}
              radius="lg"
              title="Error"
            >
              {error}
            </Alert>
          </Grid.Col>
        )}

        <Grid.Col>
          <Space h="sm" />
          <Button
            loading={registering}
            fullWidth
            text="Crear cuenta"
            type="submit"
          />
        </Grid.Col>
      </Grid>
    </form>
  )
}
