import { AuthProvider } from 'contexts/AuthContext'
import Switcher from 'navigation/Switcher'
import { UpdateAvailable } from 'components'
import { Container, MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { NotificationsProvider as FirebaseNotificationsProvider } from 'contexts/NotificationsContext'
import 'dayjs/locale/es'
import { DatesProvider } from '@mantine/dates'
import '@fontsource/poppins'

const defaultHeight = 44

function App() {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        fontFamily:
          'Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
        colors: {
          brand: [
            '#c2fff6',
            '#85ffee',
            '#48ffe5',
            '#0cffdc',
            '#00ceb0',
            '#00917c',
            '#005448',
            '#003f36',
            '#002a24',
            '#001512',
          ],
        },
        primaryColor: 'brand',
        components: {
          Button: {
            defaultProps: {
              styles: { root: { height: defaultHeight } },
            },
          },
          TextInput: {
            defaultProps: {
              styles: { input: { height: defaultHeight } },
            },
          },
          FileInput: {
            defaultProps: {
              styles: { input: { height: defaultHeight } },
            },
          },
          Select: {
            defaultProps: {
              styles: { input: { height: defaultHeight } },
            },
          },
          PasswordInput: {
            defaultProps: {
              styles: {
                input: { height: defaultHeight },
                innerInput: { height: defaultHeight },
              },
            },
          },
          DatePickerInput: {
            defaultProps: {
              styles: { input: { height: defaultHeight } },
            },
          },
        },
      }}
    >
      <Notifications position="bottom-center" />
      <AuthProvider>
        <FirebaseNotificationsProvider>
          <DatesProvider settings={{ locale: 'es', firstDayOfWeek: 1 }}>
            <Container id="app" size="xs" px={0} mih="100vh">
              <Switcher />
              <UpdateAvailable />
            </Container>
          </DatesProvider>
        </FirebaseNotificationsProvider>
      </AuthProvider>
    </MantineProvider>
  )
}

export default App
