import { daysAfterCloseRecord, publicationPeriod } from 'constants/globals'

export const certificateAvailabilityDate = (closedDate) => {
  const date = closedDate
  date.setDate(date.getDate() + publicationPeriod)
  return date.toISOString().substring(0, 10)
}

export const emissionDate = (date) => {
  const d = new Date(date)
  const ed = new Date(d.setDate(d.getDate() + daysAfterCloseRecord))
  return ed
}