import {
  Container,
  Grid,
  Image,
  Loader,
  PasswordInput,
  Text,
} from '@mantine/core'
import { Button } from 'components'
import { useAuth, useFirebase } from 'hooks'
import { Navigate } from 'react-router-dom'
import { routes } from 'navigation/routes'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getParameterByName } from 'lib/utils'
import {
  handleRecoverEmail,
  handleResetPassword,
  handleVerifyEmail,
} from 'utils/firebase'
import { hasLength, useForm } from '@mantine/form'

export default function EmailActionHandler() {
  const { auth } = useFirebase()
  const [error, setError] = useState(false)

  const mode = getParameterByName('mode')
  const actionCode = getParameterByName('oobCode')
  const isBadLink = !mode || !actionCode

  return (
    <Container
      fluid
      component="main"
      pt={16}
      pb={16}
      mih="100vh"
      width="100%"
      bg="white"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 12,
        textAlign: 'center',
        textWrap: 'balance',
      }}
    >
      <Image
        maw={200}
        fit="contain"
        src="/images/assets/logo_tag.png"
        pos="fixed"
        top={24}
        left={16}
        right={16}
        mx="auto"
      />
      {(() => {
        if (isBadLink) {
          return <ErrorComponent text="La URL ha caducado o no es correcta." />
        }
        if (error) {
          return <ErrorComponent text={error} />
        }
        if (mode === 'verifyEmail') {
          return (
            <EmailVerificationUI
              code={actionCode}
              auth={auth}
              onError={setError}
            />
          )
        }
        if (mode === 'resetPassword') {
          return (
            <ResetPasswordUI code={actionCode} auth={auth} onError={setError} />
          )
        }
        if (mode === 'recoverEmail') {
          return (
            <RecoverEmailUI code={actionCode} auth={auth} onError={setError} />
          )
        }
      })()}
    </Container>
  )
}

const EmailVerificationUI = ({ auth, code, onError }) => {
  const [isPending, setIsPending] = useState(true)

  useEffect(() => {
    handleVerifyEmail(auth, code)
      .then(({ success }) => {
        if (!success) return onError('No se ha podido verificar el correo')
      })
      .finally(() => setIsPending(false))
  }, [])

  if (auth?.currentUser?.emailVerified)
    return <Navigate to={routes.home} replace />

  if (isPending) return <LoadingComponent />

  return (
    <>
      <Image maw={224} fit="contain" src="/images/assets/success.png" />
      <Text>Tu email ha sido verificado. Ya puedes iniciar sesión.</Text>
      <Button component={Link} to={routes.home} text="Continuar" mt={64} />
    </>
  )
}

const ResetPasswordUI = ({ auth, code, onError }) => {
  const [isPending, setIsPending] = useState(false)
  const [email, setEmail] = useState()
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const init = async () => {
      const { email, error } = await handleResetPassword(auth, code).verify()

      if (error) {
        return onError(
          'El enlace es invalido o ha expirado. Por favor intenta de nuevo.'
        )
      }

      setEmail(email)
    }
    init()
  }, [])

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: hasLength(
        { min: 6, max: 18 },
        'La contraseña tiene que tener mínimo 6 caracteres y máximo 18.'
      ),
      confirmPassword: (value, values) =>
        value !== values.password ? 'La contraseña no coincide' : null,
    },
  })

  const handleSubmit = async (values) => {
    if (!email) return onError('No se ha podido verificar el correo')

    setIsPending(true)
    const { error } = await handleResetPassword(auth, code).reset(
      email,
      values.password
    )

    if (error) {
      setIsPending(false)
      return onError(error.message)
    }

    setSuccess(true)
  }

  if (!email) return <LoadingComponent />

  if (success)
    return (
      <>
        <Image maw={224} fit="contain" src="/images/assets/success.png" />
        <Text>Se ha cambiado la contraseña. Ya puedes iniciar sesión.</Text>
        <Button component={Link} to={routes.sign_in} text="Continuar" mt={64} />
      </>
    )

  return (
    <>
      <div>
        <Text>Introduce la nueva contraseña para:</Text>
        <Text weight={600}>{email}</Text>
      </div>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid sx={{ textAlign: 'left' }}>
          <Grid.Col>
            <PasswordInput
              required
              label="Contraseña"
              withAsterisk={false}
              disabled={isPending}
              {...form.getInputProps('password')}
            />
          </Grid.Col>
          <Grid.Col>
            <PasswordInput
              required
              label="Confirma la nueva contraseña"
              withAsterisk={false}
              disabled={isPending}
              {...form.getInputProps('confirmPassword')}
            />
          </Grid.Col>
          <Grid.Col>
            <Button type="submit" text="Cambiar contraseña" fullWidth />
          </Grid.Col>
        </Grid>
      </form>
    </>
  )
}

const RecoverEmailUI = ({ auth, code, onError }) => {
  const [isPending, setIsPending] = useState(false)
  const [success, setSuccess] = useState(false)
  const { user } = useAuth()
  const { updateUser } = useFirebase()

  useEffect(() => {
    handleRecoverEmail(auth, code)
      .then(({ success, email }) => {
        if (!success) return onError('No se ha podido recuperar el correo.')
        if (user) {
          updateUser({
            data: { email },
            docId: user.userDocId,
          })
        }
        setSuccess(true)
      })
      .finally(() => setIsPending(false))
  }, [])

  if (isPending) return <LoadingComponent />

  if (success)
    return (
      <>
        <Text size="xl" weight={700}>
          Éxito
        </Text>
        <Text>
          Hemos restaurado tu correo electrónico. También te hemos enviado un
          correo para cambiar tu contraseña en caso de que tu cuenta se haya
          visto comprometida.
        </Text>
      </>
    )

  return null
}

const ErrorComponent = ({ text }) => (
  <>
    <Image maw={224} fit="contain" src="/images/assets/wrong.png" />
    <Text size="xl" weight={700}>
      Algo fue mal
    </Text>
    <Text>{text}</Text>
    <Button
      component={Link}
      to={routes.home}
      text="Volver a la página principal"
      mt={12}
    />
  </>
)

const LoadingComponent = () => (
  <>
    <Image maw={224} fit="contain" src="/images/assets/working.png" />
    <Text>Un momento por favor...</Text>
    <Loader color="brand" variant="dots" />
  </>
)
