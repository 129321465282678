import { Box, Flex, Paper, Text, Tooltip, UnstyledButton } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { DocumentDownload } from 'iconsax-react'

const getBg = (category) => {
  const images = [
    '/images/records/itec-card.png',
    '/images/records/eieec-card.png',
    '/images/records/ifc-card.png',
    '/images/records/igac-card.png',
    '/images/records/igbc-card.png',
    '/images/records/igcc-card.png',
    '/images/records/apmc-card.png',
    '/images/records/apmadc-card.png',
    '/images/records/ad-card.png',
  ]

  if (category > images.length) {
    return '/images/records/default-card.png'
  }

  return images[category - 1]
}

export default function CertificateCard({
  title,
  category,
  code,
  startDate,
  endDate,
  fileLink,
}) {
  const expired = new Date(endDate) < new Date()

  function handleDownload() {
    // console.log({ fileLink })
    if (fileLink) {
      window.open(fileLink, '_blank', 'noreferrer')
    } else {
      showNotification({
        message:
          'Tu certificado se está tramitando, por favor, prueba en otro momento.',
        color: 'blue',
      })
    }
  }

  return (
    <Paper shadow="xs" radius="lg" pos="relative" sx={{ overflow: 'hidden' }}>
      <Box
        sx={{
          backgroundImage: `url(${getBg(category)})`,
          position: 'absolute',
          inset: 0,
          zIndex: 0,
          transform: 'scale(2)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          filter: 'blur(12px)',
        }}
      />
      {expired && (
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.7)',
            zIndex: 2,
          }}
        >
          <Text
            component="h1"
            color="red.7"
            fz="2.5rem"
            sx={{
              transform: 'rotate(-20deg)',
              textShadow:
                '1px 1px 4px rgb(0 0 0 / 60%), 1px 1px 6px rgb(0 0 0 / 30%), -1px -1px 4px rgb(255 255 255 / 30%),  1px 1px 6px rgb(255 255 255 / 10%)',
            }}
          >
            Expirada
          </Text>
        </Box>
      )}
      <Box
        pos="relative"
        p="md"
        sx={{
          zIndex: 1,
          color: 'white',
          background: 'rgba(0,0,0,0.4)',
          backdropFilter: 'blur(5px)',
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: 12,
          minHeight: 220,
          '& .mantine-Text-root': {
            textShadow: '0px 0px 3px rgba(0,0,0,1)',
          },
        }}
      >
        <Text fw={500} fz="lg" align="center" lh="24px">
          {title}
        </Text>
        <Text fw={500} fz={28} align="center">
          {code}
        </Text>
        <Flex align="center" gap="lg">
          <Box sx={{ flex: 1 }}>
            <Flex justify="space-between">
              <Text>Entrada en vigor:</Text>
              <Text>{startDate}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text>Expiración:</Text>
              <Text>{endDate}</Text>
            </Flex>
          </Box>
          <Tooltip label="Descargar certificado">
            <UnstyledButton
              disabled={expired}
              p={8}
              display="flex"
              onClick={handleDownload}
              sx={{
                transition: 'transform 200ms ease-in-out',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: 8,
                '&:active': {
                  transform: 'scale(0.85)',
                },
              }}
            >
              <DocumentDownload size={24} variant="Bold" />
            </UnstyledButton>
          </Tooltip>
        </Flex>
      </Box>
    </Paper>
  )
}
