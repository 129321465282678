import { useNavigate } from 'react-router-dom'
import {
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { Button } from 'components'
import { Anchor, Grid, PasswordInput, Space, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { useAuth } from 'hooks'
import { useState } from 'react'
import { routes } from 'navigation/routes'
import { DEBUG } from 'utils'
function handleErrors(code) {
  switch (code) {
    case 'auth/wrong-password':
      return {
        email: null,
        password: 'La contraseña no coincide',
      }
    case 'auth/invalid-email':
    case 'auth/user-not-found':
      return {
        email:
          'El usuario no existe. Comprueba la dirección de correo electrónico.',
        password: null,
      }
    case 'auth/too-many-requests':
      return {
        email: null,
        password: 'Has realizado demasiados intentos. Prueba más tarde.',
      }
    case 'auth/email-not-verified':
      return {
        email: 'El correo electrónico no ha sido verificado',
        password: null,
      }
    default:
      return {
        email: null,
        password: 'Credenciales incorrectas, inténtelo de nuevo',
      }
  }
}

export default function SignInForm() {
  const [loading, setLoading] = useState(false)
  const [notVerified, setNotVerified] = useState(false)

  const { signIn } = useAuth()
  let navigate = useNavigate()

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
  })

  const handleLogin = async (values) => {
    setLoading(true)

    const { error } = await signIn({
      email: values.email,
      password: values.password,
    })

    if (error) {
      setLoading(false)
      if (error?.code === 'auth/email-not-verified') {
        setNotVerified(true)
      }

      return form.setErrors(handleErrors(error.code))
    }

    return navigate(routes.home, { replace: true })
  }

  const sendRecoveryPasswordEmail = () => {
    const auth = getAuth()
    const email = form.values.email

    sendPasswordResetEmail(auth, email)
      .then(() => {
        showNotification({
          title: 'Recuperación de contraseña',
          message:
            'Le hemos enviado un correo con un enlace para recuperar su contraseña.',
          autoClose: 10000,
        })
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        form.setFieldError(
          'email',
          'Por favor introduce el email con el que te registraste.'
        )
        if (DEBUG) {
          console.log(errorCode, errorMessage)
        }
      })
  }

  const handleSendVerificationEmail = async () => {
    try {
      const auth = getAuth()
      await sendEmailVerification(auth.currentUser)
      return navigate(routes.emailSent, { replace: true })
    } catch (error) {
      showNotification({
        title: 'Error',
        message:
          'No hemos podido enviar el correo de verificación. Recarga la página e intenta de nuevo. Por favor intenta en otro momento, o ponte en contacto con nosotros.',
        color: 'red',
      })
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleLogin)}>
      <Grid>
        <Grid.Col>
          <TextInput
            required
            withAsterisk={false}
            label="Correo electrónico"
            type="email"
            disabled={notVerified}
            {...form.getInputProps('email')}
          />
        </Grid.Col>
        <Grid.Col>
          <PasswordInput
            required
            withAsterisk={false}
            label="Contraseña"
            disabled={notVerified}
            {...form.getInputProps('password')}
          />
          <Space h="xs" />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Anchor
              component="button"
              onClick={sendRecoveryPasswordEmail}
              align="right"
              fz="sm"
              display="block"
              disabled={notVerified}
            >
              ¿Has olvidado tu contraseña?
            </Anchor>
          </div>
        </Grid.Col>
        <Grid.Col>
          <Space h="sm" />
          <Button
            loading={loading}
            fullWidth
            text="Iniciar sesión"
            type="submit"
            disabled={notVerified}
          />

          {notVerified && (
            <Button
              loading={loading}
              fullWidth
              text="Recibir enlace de verificación"
              variant="light"
              mt={12}
              onClick={(e) => {
                e.preventDefault()
                handleSendVerificationEmail()
              }}
            />
          )}
        </Grid.Col>
      </Grid>
    </form>
  )
}
