import { useState } from 'react'
import { Container, SegmentedControl } from '@mantine/core'
import { RequestsTab, ExamsTab, DocumentsTab } from 'pages'

export default function MyFolder() {
  const [tab, setTab] = useState('requests')

  return (
    <>
      <Container
        size="xs"
        px={0}
        bg="white"
        sx={{ position: 'fixed', top: 0, left: 0, right: 0 }}
      >
        <SegmentedControl
          value={tab}
          onChange={setTab}
          fullWidth
          size="md"
          radius="md"
          m="sm"
          data={[
            { label: 'Solicitudes', value: 'requests' },
            { label: 'Exámenes', value: 'exams' },
            { label: 'Documentos', value: 'documents' },
          ]}
          styles={{
            active: { boxShadow: 'none' },
            label: { fontSize: '0.9rem' },
          }}
        />
      </Container>
      <Container px={0} pt={60} pb="lg">
        {tab === 'requests' && <RequestsTab />}
        {tab === 'exams' && <ExamsTab />}
        {tab === 'documents' && <DocumentsTab />}
      </Container>
    </>
  )
}
