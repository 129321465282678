import React from 'react'
import { Export, Document, Image } from 'iconsax-react'
import { Button } from 'components'
import {
  Input,
  Text,
  Box,
  FileInput as MantineFileInput,
  Group,
  CloseButton,
  Stack,
} from '@mantine/core'
import { showNotification } from '@mantine/notifications'

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return bytes

  const k = 1048576
  const dm = decimals < 0 ? 0 : decimals

  return parseFloat((bytes / k).toFixed(dm))
}

function FileInput({
  id,
  form,
  label,
  accept,
  description,
  required,
  maxSize,
  maxFiles,
  ...props
}) {
  const values = form.values[id] ?? []

  const handleChange = (files) => {
    let filesData = []
    for (var i = 0; i < files.length; i++) {
      let file = files[i]
      let type = file.type
      let size = formatBytes(file.size, 2)
      const acceptedType = accept.split(',').some((r) => {
        return type.includes(r)
      })

      if (maxSize && size > maxSize) {
        showNotification({
          message: `Se ha superado el tamaño máximo de archivo, ${maxSize}MB.`,
          color: 'red',
        })
        filesData = []
        return
      }
      if (maxFiles && (values?.length || 0) + files.length > maxFiles) {
        showNotification({
          message: `Se ha superado el número máximo de archivos, ${maxFiles}.`,
          color: 'red',
        })
        filesData = []
        return
      }
      if (!acceptedType) {
        showNotification({
          message: `El tipo de archivo no está permitido, solo se permiten ${accept
            .replaceAll(',', ', ')
            .replaceAll('image/', '.')
            .replaceAll('application/', '.')
            .replaceAll('*', '')}.`,
          color: 'red',
        })
        filesData = []
        return
      }

      filesData.push({ data: file, url: URL.createObjectURL(file) })
    }

    form.setFieldValue(id, values.concat(filesData))
  }

  const handleDeleteFile = (index) => {
    const newValues = [].concat(values)
    newValues.splice(index, 1)

    form.setFieldValue(id, newValues)
  }

  return (
    <>
      <Input.Wrapper
        label={label}
        description={description}
        required={required}
      >
        <MantineFileInput
          id={id}
          accept={accept}
          multiple
          hidden
          aria-hidden
          {...props}
          {...form.getInputProps(id)}
          onChange={handleChange}
          sx={{ display: 'none' }}
        />
        <Input.Label
          htmlFor={id}
          sx={{ position: 'relative', marginTop: 8, width: '100%' }}
        >
          <Button
            fullWidth
            variant="outline"
            component="span"
            text="Seleccionar archivos"
            leftIcon={<Export variant="Linear" />}
          />
        </Input.Label>
      </Input.Wrapper>
      {values?.length > 0 && (
        <Stack mt={12} spacing="xs">
          {values.map(({ data }, index) => (
            <Group
              key={data.name}
              position="apart"
              px={12}
              py={6}
              noWrap
              sx={(theme) => ({
                border: `1px solid ${theme.colors.gray[4]}`,
                borderRadius: 6,
                height: 60,
              })}
            >
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  gap: 18,
                  alignItems: 'center',
                  width: '70%',
                }}
              >
                {data.type === 'application/pdf' ? (
                  <Box p="xs" bg="red.1" sx={{ borderRadius: 8 }}>
                    <Text span color="red.8" display="flex">
                      <Document size={22} variant="Bulk" />
                    </Text>
                  </Box>
                ) : (
                  <Box p="xs" bg="blue.1" sx={{ borderRadius: 8 }}>
                    <Text span color="blue.8" display="flex">
                      <Image size={22} variant="Bulk" />
                    </Text>
                  </Box>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Text truncate inline lh="20px" mb={2}>
                    {data.name}
                  </Text>
                  <Text truncate inline fz="xs" color="dimmed">
                    {`${formatBytes(data.size)} MB`}
                  </Text>
                </Box>
              </Box>
              <CloseButton
                onClick={() => handleDeleteFile(index)}
                aria-label="Eliminar archivo"
              />
            </Group>
          ))}
        </Stack>
      )}
    </>
  )
}

export default React.memo(FileInput)
