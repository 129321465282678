import { useCallback, useReducer, useEffect } from 'react'
import { useFirebase, useAuth } from 'hooks'

export const REQUEST_STATE = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  SENT: 'sent',
}

export default function useRequests() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { loading: true, data: [], error: null }
  )
  const { user } = useAuth()
  const { getDataByKey, requestsColl } = useFirebase()

  const getRequests = useCallback(async (user) => {
    try {
      const requests = await getDataByKey({
        coll: requestsColl,
        key: 'uid',
        value: user.uid,
      })
      const data =
        requests && requests.length
          ? requests.map((request) => ({
              date: request.data.date.toDate(),
              state: request.data.state,
              category: request.data.certification.type,
              id: request.docId.substring(-5, 5),
            }))
          : []
      setState({ data, loading: false })
    } catch (error) {
      setState({ loading: false, error })
      throw error
    }
  }, [])

  useEffect(() => {
    if (user) {
      getRequests(user)
    }
  }, [user])

  return state
}
