import { Route, Routes, Navigate } from 'react-router-dom'
import {
  Certificates,
  Home,
  Layout,
  MyData,
  MyFolder,
  Notifications,
  RequestForm,
  SignIn,
} from 'pages'
import { routes } from './routes'
import RequireAuth from './RequireAuth'
import { useAuth } from 'hooks'
import { CenterLoading } from 'components'
import EmailSent from 'pages/EmailSent'
import EmailActionHandler from 'pages/EmailActionHandler'
import MissingUserData from 'pages/MissingUserData'

export default function Switcher() {
  const { isAuthLoading: loading } = useAuth()

  if (loading) return <CenterLoading />

  return (
    <>
      <Routes>
        <Route path={routes.sign_in} element={<SignIn />} />
        <Route path={routes.emailSent} element={<EmailSent />} />
        <Route path={routes.emailAction} element={<EmailActionHandler />} />
        <Route path={routes.missingUserData} element={<MissingUserData />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route index element={<Home />} />
          <Route path={routes.certificates} element={<Certificates />} />
          <Route path={routes.myFolder} element={<MyFolder />} />
          <Route path={routes.notifications} element={<Notifications />} />
        </Route>
        <Route
          path={routes.myData}
          element={
            <RequireAuth>
              <MyData />
            </RequireAuth>
          }
        />
        <Route
          path={routes.request}
          element={
            <RequireAuth>
              <RequestForm />
            </RequireAuth>
          }
        />

        <Route path="*" element={<Navigate replace to={'/'} />} />
      </Routes>
    </>
  )
}
