import { Container } from '@mantine/core'
import { BottomBar } from 'components'
import { Outlet } from 'react-router-dom'

export default function Layout() {
  return (
    <>
      <Container fluid component="main" pt={16} pb={64} mih="100vh" bg="white">
        <Outlet />
      </Container>
      <BottomBar />
    </>
  )
}
