export const differences = (prev, current) => {
  let result = []
  let previewSet = prev.filter((item) => !current.includes(item))
  let newSet = current.filter((item) => !prev.includes(item))

  if (previewSet.length > 0) {
    let obj1 = { value: previewSet, operation: 'delete' }
    result.push(obj1)
  }

  if (newSet.length > 0) {
    let obj2 = { value: newSet, operation: 'add' }
    result.push(obj2)
  }

  return result
}
