import { useCallback, useEffect, useReducer } from 'react'
import { useFirebase } from 'hooks'
import { DEBUG } from 'utils'

export default function useCategories(candidateDocId = undefined) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { loading: true, categories: [], error: null }
  )

  const { getAllDocuments, categoriesColl, getUserCategories } = useFirebase()

  const getCategories = useCallback(async (docId) => {
    let categories = []

    if (docId === null) {
      return
    }

    try {
      if (docId) {
        categories = await getUserCategories(docId)
      } else {
        const categoriesDocs = await getAllDocuments({
          coll: categoriesColl,
        })
        categories = categoriesDocs.map((category) => {
          const obj = {
            value: category.data.code,
            label: `${category.data.code} - ${category.data.name}`,
          }
          return obj
        })
      }
    } catch (error) {
      if (DEBUG) {
        console.log({ error })
      }
      setState({ loading: false, error })
    }

    setState({ loading: false, categories })
  }, [])

  useEffect(() => {
    getCategories(candidateDocId)
  }, [candidateDocId])

  return state
}
