import { useCallback, useEffect, useReducer } from 'react'
import { useFirebase } from 'hooks'
import { DEBUG } from 'utils'

export default function useCenters() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { loading: true, centers: [], error: null }
  )

  const { getAllDocuments, centersColl } = useFirebase()

  const getCenters = useCallback(async () => {
    try {
      const centersDocs = await getAllDocuments({
        coll: centersColl,
        orderKey: 'name',
      })
      const centers = centersDocs.map((center) => {
        const obj = {
          value: center.data.id,
          label: center.data.name,
        }
        return obj
      })
      setState({ loading: false, centers })
    } catch (error) {
      if (DEBUG) {
        console.log({ error })
      }
      setState({ loading: false, error })
    }
  }, [])

  useEffect(() => {
    getCenters()
  }, [])

  return state
}
