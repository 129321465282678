import { Container, Text } from '@mantine/core'
import { CenterLoading, UserDataForm } from 'components'
import { useAuth } from 'hooks'
import { routes } from 'navigation/routes'
import { Navigate } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

export default function MissingUserData() {
  const { user, loading } = useAuth()
  const navigate = useNavigate()

  if (loading) return <CenterLoading />

  if (user) return <Navigate to={routes.home} replace />

  return (
    <Container
      fluid
      component="main"
      pt={16}
      pb={64}
      mih="100vh"
      bg="white"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
      }}
    >
      <div>
        <Text size="xl" weight={700}>
          Completa tu perfil
        </Text>
        <Text>Por favor completa tus datos para poder iniciar sesión.</Text>
      </div>
      <UserDataForm
        onSuccess={() => navigate(routes.home, { replace: true })}
      />
    </Container>
  )
}
