import { NotificationsContext } from 'contexts/NotificationsContext'
import { useContext } from 'react'

export default function useNotifications() {
  const context = useContext(NotificationsContext)
  if (!context) {
    throw new Error(
      'useNotifications must be used within a NotificationsContext.Provider'
    )
  }

  return context
}
