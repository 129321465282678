import { Flex, Paper, Text, Image, Box, Skeleton } from '@mantine/core'
import certifiqueSymbol from 'assets/pictures/symbol.png'
import { useAuth, useCertificates } from 'hooks'

const idW = 378
const idH = 508

export default function PersonalCard() {
  const { count } = useCertificates()
  let { user } = useAuth()

  const candidateCardId = user?.candidateId
    ? String(user.candidateId).padStart(6, '0')
    : ''

  return (
    <Paper
      shadow="lg"
      radius="lg"
      withBorder
      pos="relative"
      sx={{
        overflow: 'hidden',
      }}
    >
      <Flex
        pos="relative"
        align="flex-start"
        gap={16}
        wrap
        p="md"
        sx={{ width: '100%', zIndex: 1 }}
      >
        <Image
          width={idW / 4}
          height={idH / 4}
          src={count > 0 ? user?.picture : null}
          alt="Foto carnet"
          withPlaceholder
          radius="md"
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Text fz="sm" inline c="dimmed">
            Nombre
          </Text>
          {count > 0 ? (
            <Text>{user.name}</Text>
          ) : (
            <Skeleton
              height={16}
              mt={6}
              radius="xs"
              width="50%"
              animate={false}
              mb={8}
            />
          )}

          <Text inline fz="sm" c="dimmed">
            Apellidos
          </Text>
          {count > 0 ? (
            <Text>{user.lastname}</Text>
          ) : (
            <Skeleton
              height={16}
              mt={6}
              radius="xs"
              width="90%"
              animate={false}
              mb={8}
            />
          )}

          <Text inline fz="sm" c="dimmed">
            DNI/NIE
          </Text>
          {count > 0 ? (
            <Text>{user.id}</Text>
          ) : (
            <Skeleton
              height={16}
              mt={6}
              radius="xs"
              width="30%"
              animate={false}
            />
          )}
        </Box>
      </Flex>
      <Flex align="center" justify="flex-end" p={12} bg="brand">
        <Text inline color="white">
          ID:
        </Text>
        {count > 0 ? (
          <Text inline color="white" ml={4}>
            {candidateCardId}
          </Text>
        ) : (
          <Skeleton
            height={16}
            ml={6}
            radius="xs"
            width={80}
            animate={false}
            opacity={0.2}
          />
        )}
      </Flex>
      <Image
        width={150}
        src={certifiqueSymbol}
        alt="Logo decoration"
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          opacity: 0.15,
          zIndex: 0,
          transform: 'scale(1.3)',
        }}
      />
    </Paper>
  )
}
