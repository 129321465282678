import {
  Alert,
  Box,
  Divider,
  Flex,
  Group,
  Loader,
  Modal,
  Paper,
  ScrollArea,
  Skeleton,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core'
import { dateFormat, darkenColor, calcTotal, DEBUG } from 'utils'
import {
  Calendar2,
  Clock,
  InfoCircle,
  Information,
  Location,
  UserEdit,
} from 'iconsax-react'
import es from 'translations/es.json'
import { useAuth, useExams, useFirebase } from 'hooks'
import { EXAM_STATE } from 'hooks/useExams'
import { certificateAvailabilityDate } from 'utils/getCertificateAvailabilityDate'
import { Button } from 'components'
import { useDisclosure } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'lucide-react'
import { minPercentageToPass } from 'constants/globals'

export default function ExamsTab() {
  const { data, loading } = useExams()

  if (loading)
    return (
      <Stack>
        {new Array(2).fill('').map((it, idx) => (
          <Skeleton key={idx} radius="lg" height={200} />
        ))}
      </Stack>
    )

  if (!loading && data.length === 0)
    return (
      <Alert color="brand" my={12} radius="lg">
        <Text>
          Actualmente no hay ningún examen que mostrar. Aquí podrás consultar
          los exámenes pendientes de realizar y los realizados.
        </Text>
      </Alert>
    )

  return (
    <>
      <Stack>
        {data.map((exam) => (
          <ExamItem key={exam.id} {...exam} />
        ))}
      </Stack>
    </>
  )
}

const ExamItem = ({
  date,
  time,
  examiner,
  address,
  state,
  recordId,
  record,
  result,
  closed_date,
  th,
  p,
  finished,
}) => {
  const theme = useMantineTheme()
  const [opened, { toggle, close }] = useDisclosure(false)

  const getStatusColor = () => {
    switch (state) {
      case EXAM_STATE.PENDING:
        return theme.colors.blue[5]
      case EXAM_STATE.CORRECTING:
        return theme.colors.brand[5]
      case EXAM_STATE.CLAIMS:
        return theme.colors.indigo[5]
      default:
        return theme.colors.gray[6]
    }
  }

  return (
    <>
      <Paper radius={16} bg={getStatusColor()} className="bg-waves">
        <Group m="xs" position="apart">
          <Text fz="lg" c="white" ml={6} inline>
            Exp.:{' '}
            <Text span fz="lg" c="white" fw={500} inline>
              {record}
            </Text>
          </Text>
          <Text
            fz="sm"
            inline
            bg={darkenColor(getStatusColor(), 20)}
            c="white"
            py={6}
            px={10}
            sx={{ borderRadius: 6 }}
          >
            {es[state]}
          </Text>
        </Group>
        <Paper p="sm" m={4} radius={12} mih={80}>
          <Flex c="dimmed" gap="xs" mt={8}>
            <Calendar2 size={18} />
            <Text fz="sm">{dateFormat(date, 'dd MMM yyyy')}</Text>
          </Flex>
          <Flex c="dimmed" gap="xs" mt={8}>
            <Clock size={18} />
            <Text fz="sm">{`Teoría: ${time?.th || '--:--'} | Práctica: ${
              time?.p || '--:--'
            }`}</Text>
          </Flex>
          <Flex c="dimmed" gap="xs" mt={8}>
            <Location size={18} />
            <Text fz="sm" sx={{ flex: 1 }} mt={2} lh={1.1}>
              {address}
            </Text>
          </Flex>
          <Flex c="dimmed" gap="xs" mt={8}>
            <UserEdit size={18} />
            <Text fz="sm" sx={{ flex: 1 }} mt={2} lh={1.1}>
              {examiner}
            </Text>
          </Flex>
          <Divider my="sm" />
          <Group grow spacing="xs">
            <Box
              p={4}
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                border: `1px solid ${theme.colors.gray[4]}`,
                borderRadius: 12,
              })}
            >
              <Text fz="sm" c="dimmed" align="center" inline mb={4}>
                Teoría
              </Text>
              <Text fz="sm" align="center" c="dark">
                {es[th] ?? '-'}
              </Text>
            </Box>
            <Box
              p={4}
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                border: `1px solid ${theme.colors.gray[4]}`,
                borderRadius: 12,
              })}
            >
              <Text fz="sm" c="dimmed" align="center" inline mb={4}>
                Práctica
              </Text>
              <Text fz="sm" align="center" c="dark">
                {es[p] ?? '-'}
              </Text>
            </Box>
            <Box
              p={4}
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                border: `1px solid ${theme.colors.gray[4]}`,
                borderRadius: 12,
              })}
            >
              <Text fz="sm" c="dimmed" align="center" inline mb={4}>
                Resolución
              </Text>
              <Text fz="sm" align="center" fw={500} c="dark">
                {es[result] ?? '-'}
              </Text>
            </Box>
          </Group>
          {(() => {
            if (state === EXAM_STATE.CLAIMS) {
              if (closed_date && result === 'competent')
                return (
                  <Flex mt={8} c="dimmed" gap="xs">
                    <InfoCircle />
                    <Text fz="sm">
                      {`El certificado estará disponible aproximadamente para el ${dateFormat(
                        certificateAvailabilityDate(closed_date),
                        'dd MMM yyyy'
                      )}`}
                    </Text>
                  </Flex>
                )
            }
            if (state === EXAM_STATE.PENDING) {
              return (
                <Flex mt={8} gap="xs">
                  <Information />
                  <Text fz="sm">
                    Presentarse{' '}
                    <Text span fw={600}>
                      con DNI 15 minutos antes
                    </Text>{' '}
                    de la hora del examen
                  </Text>
                </Flex>
              )
            }

            return null
          })()}
          {/* Show Button when exam has finished */}
          {/* {date &&
            date.getTime() + 20 * 60 * 60 * 1000 < new Date().getTime() && (
              <Flex justify="end">
                <Button
                  variant="subtle"
                  mt={8}
                  text="Ver plantilla de respuestas"
                  onClick={toggle}
                />
              </Flex>
            )} */}
          {finished && state !== EXAM_STATE.CLOSED && (
            <Flex justify="end">
              <Button
                variant="subtle"
                mt={8}
                text="Ver plantilla de respuestas"
                onClick={toggle}
              />
            </Flex>
          )}
        </Paper>
      </Paper>
      <Modal
        opened={opened}
        onClose={close}
        radius="lg"
        maw={490}
        mx="auto"
        centered
        scrollAreaComponent={ScrollArea.Autosize}
        title="Plantilla de respuestas"
      >
        <Text c="dimmed" size={14}>
          A continuación se muestran tus respuestas en el examen.
        </Text>
        <Box py={2}>
          <ResponsesTemplate
            recordId={recordId}
            provisional={state !== EXAM_STATE.CLOSED}
          />
        </Box>
      </Modal>
    </>
  )
}

const ResponsesTemplate = ({ recordId, provisional }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [responses, setResponses] = useState({ r: [], tp: [] })
  const { user } = useAuth()
  const { getDataByKey, examsColl } = useFirebase()

  useEffect(() => {
    if (recordId && user) {
      const getData = async () => {
        try {
          const response = await getDataByKey({
            coll: examsColl(user.candidateDocId),
            key: 'record',
            value: recordId,
            unique: true,
          })

          const data = response.data

          const r =
            data.r?.map((i) => ({
              answer: i.answer,
              index: i.q,
              value: i.value,
            })) || []
          const tp =
            data.tp?.map((i) => ({
              answer: i.answer,
              index: i.q,
              value: i.value,
            })) || []
          setResponses({ r, tp })
        } catch (error) {
          // TODO: handle error
          if (DEBUG) {
            console.log({ error })
          }
        } finally {
          setIsLoading(false)
        }
      }
      getData()
    }
  }, [recordId])

  const NotAvailable = () => (
    <section>
      <Text>Las respuestas no están disponibles para este examen.</Text>
    </section>
  )

  const total = calcTotal(responses.r).total + calcTotal(responses.tp).total
  const max = responses.r.length + responses.tp.length

  const getColor = (value) => {
    switch (value) {
      case 'good':
        return 'inherit'
      case 'bad':
        return 'red'
      default:
        return 'grey'
    }
  }

  const Responses = () => (
    <>
      <Flex gap="md">
        <Box sx={{ flex: 1 }}>
          <Text mb={8} lh={1}>
            Parte 1
          </Text>
          {responses.r.length > 0 && (
            <>
              {responses.r.map((r, idx) => (
                <Flex
                  key={`r-${idx}`}
                  bg={idx % 2 === 0 ? 'gray.0' : 'gray.2'}
                  py={4}
                  px={12}
                  justify="space-between"
                  align="center"
                >
                  <Text w="33.3%">{r.index}.</Text>
                  <Text w="33.3%" align="center" c={getColor(r.value)}>
                    {r.answer || '-'}
                  </Text>
                  <Text
                    align="right"
                    c={getColor(r.value)}
                    fz={14}
                    sx={{ flexGrow: 1 }}
                  >
                    {(() => {
                      if (r.value === 'da') return '+0'
                      if (r.value === 'good') return '+1'
                      if (r.value === 'bad') return '-0.25'
                      return ''
                    })()}
                  </Text>
                </Flex>
              ))}
              <Text fw={500} my={8} align="right">
                {`Suma: ${calcTotal(responses.r).total}`}
              </Text>
            </>
          )}
        </Box>
        <Box sx={{ flex: 1 }}>
          <Text mb={8} lh={1}>
            Parte 2
          </Text>
          {responses.tp.map((r, idx) => (
            <Flex
              key={`r-${idx}`}
              bg={idx % 2 === 0 ? 'gray.0' : 'gray.2'}
              py={4}
              px={12}
              justify="space-between"
              align="center"
            >
              <Text w="33.3%">{r.index}.</Text>
              <Text w="33.3%" align="center" c={getColor(r.value)}>
                {r.answer || '-'}
              </Text>
              <Text
                align="right"
                c={getColor(r.value)}
                fz={14}
                sx={{ flexGrow: 1 }}
              >
                {(() => {
                  if (r.value === 'da') return '+0'
                  if (r.value === 'good') return '+1'
                  if (r.value === 'bad') return '-0.25'
                  return ''
                })()}
              </Text>
            </Flex>
          ))}
          <Text fw={500} my={8} align="right">
            {`Suma: ${calcTotal(responses.tp).total}`}
          </Text>
        </Box>
      </Flex>
      <Text mt={8} align="center" fz={18}>
        Total:{' '}
        <Text
          span
          fw={500}
          c={total >= max * minPercentageToPass ? 'green' : 'red'}
        >
          {total}
        </Text>{' '}
        de{' '}
        <Text span fw={500}>
          {max}
        </Text>
      </Text>
      <Text mt={8} align="center" fz={14} c="gray.6">
        *Mínima puntuación para aprobar:{' '}
        <Text span fw={600}>
          {minPercentageToPass * max}
        </Text>
      </Text>
      {provisional && (
        <Alert mt={12} color="yellow" icon={<AlertCircle size={16} />}>
          <Text>
            Las notas son provisionales. Serán definitivas una vez se cierre el
            expediente.
          </Text>
        </Alert>
      )}
    </>
  )

  if (isLoading) {
    return (
      <Box py={12}>
        <Loader mx="auto" display="block" />
      </Box>
    )
  }

  const isAvailable =
    (responses.r.length && responses.r.every((r) => r.answer !== undefined)) ||
    (responses.tp.length && responses.tp.every((r) => r.answer !== undefined))

  return <Box py={12}>{isAvailable ? <Responses /> : <NotAvailable />}</Box>
}
