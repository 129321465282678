import React from 'react'
import { Box, Flex, Text, UnstyledButton } from '@mantine/core'
import { darkenColor } from 'utils'
import { Link } from 'react-router-dom'

export default function LinkCard({
  to,
  text,
  subtitle,
  icon,
  color = '#F1F3F5',
  iconColor = 'gray.7',
  disabled,
  ...other
}) {
  return (
    <Box
      component={to && !disabled ? Link : UnstyledButton}
      sx={(theme) => ({
        textDecoration: 'none',
        transition: 'all 200ms ease-in-out',
        display: 'block',
        width: '100%',
        padding: 16,
        borderRadius: 12,
        ...(!disabled && {
          backgroundColor: color,
          '&:hover': {
            backgroundColor: darkenColor(color, 8),
          },
          '&:active': { transform: 'scale(0.98)' },
        }),
        ...(disabled && { backgroundColor: theme.colors.gray[3] }),
      })}
      disabled={disabled}
      {...(to && { to })}
      {...other}
    >
      <Flex align="center" gap={12}>
        {icon && (
          <Text
            inline
            component="span"
            display="flex"
            c={disabled ? 'gray.6' : iconColor}
          >
            {React.cloneElement(icon, {
              variant: 'Bulk',
              size: 24,
            })}
          </Text>
        )}
        <Box sx={{ flex: 1 }}>
          <Text inline c={disabled ? 'dimmed' : 'dark'}>
            {text}
          </Text>
          {subtitle && (
            <Text mt={6} c="dimmed" fz="sm" lh="16px">
              {subtitle}
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  )
}
