import { useEffect, useState } from 'react'
import { Alert, Grid, Select, Space, Stack, TextInput } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { hasLength, isNotEmpty, matches, useForm } from '@mantine/form'
import { Button, MaskedInput } from 'components'
import { provinces } from 'constants/provinces'
import { useAuth, useFirebase } from 'hooks'
import { showNotification } from '@mantine/notifications'
import { useNavigate } from 'react-router-dom'
import { routes } from 'navigation/routes'
import { OctagonAlert } from 'lucide-react'
import { auth } from 'configs/firebaseConfig'
// import { deleteData } from 'utils/query'
import { parseFirebaseDate } from 'utils/firebase'
import { DEBUG } from 'utils'

export default function UserDataForm({ onSuccess, disabledItems }) {
  const [isPending, setIsPending] = useState(false)
  const [isRepeated, setIsRepeated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { user, createUserAccount, signOut } = useAuth()
  const { updateUser } = useFirebase()
  const navigate = useNavigate()
  const maxDate = new Date()
  maxDate.setMonth(maxDate.getMonth() - (12 * 18 - 2))

  const form = useForm({
    initialValues: {
      name: '',
      lastname: '',
      id: '',
      phone: '',
      address: '',
      cp: '',
      city: '',
      province: '',
      birth_date: undefined,
      policy: false,
    },
    validate: {
      name: isNotEmpty('Este campo es obligatorio.'),
      lastname: isNotEmpty('Este campo es obligatorio.'),
      id: matches(/^([XYZ]\d{7}|[0-9]{8})[A-Z]$/, 'El formato es incorrecto.'),
      phone: isNotEmpty('Este campo es obligatorio.'),
      address: isNotEmpty('Este campo es obligatorio.'),
      cp: hasLength(5, 'El código postal debe tener 5 dígitos'),
      city: isNotEmpty('Este campo es obligatorio.'),
      province: isNotEmpty('Este campo es obligatorio.'),
      birth_date: isNotEmpty('Este campo es obligatorio.'),
    },
    transformValues: (values) => ({
      ...values,
      name: values.name.toLocaleUpperCase().trim(),
      lastname: values.lastname.toLocaleUpperCase().trim(),
      city: values.city.toLocaleUpperCase().trim(),
      address: values.address.trim(),
    }),
  })

  useEffect(() => {
    if (user) {
      const values = {
        ...form.values,
        name: user.name,
        lastname: user.lastname,
        id: user.id,
        phone: user.phone,
        address: user.address,
        cp: user.cp,
        city: user.city,
        province: user.province,
        birth_date: parseFirebaseDate(user?.birthdate),
      }
      form.setValues(values)
      form.resetDirty(values)
    }
  }, [user])

  const handleSubmit = async (values) => {
    setIsPending(true)

    try {
      const { birth_date: birthdate, ...rest } = values
      if (user) {
        await updateUser({
          data: { ...rest, birthdate },
          docId: user.userDocId,
        })
        onSuccess?.()
      } else {
        const { error } = await createUserAccount({ ...rest, birthdate })
        if (error) {
          if (error?.code === 'auth/user-already-exists') {
            setIsRepeated(true)
          }
          setError(error.message)
        } else {
          onSuccess?.()
        }
      }
    } catch (error) {
      showNotification({
        message: 'Ha habido un problema al actualizar tus datos.',
        color: 'red',
      })
    } finally {
      setIsPending(false)
    }
  }

  /**
   * The function is meant to be for those users that already had an account with a fake mail
   */
  const handleDeleteAccount = async () => {
    try {
      setLoading(true)
      // const url = `${process.env.REACT_APP_FIREBASE_ADMIN_ROUTE}/users/${auth.currentUser.uid}`
      // const token = await auth.currentUser.getIdToken()
      // await deleteData(url, token)
      await signOut()
      navigate(routes.home)
    } catch (error) {
      if (DEBUG) {
        console.log({ error })
      }
      setLoading(false)
    }
  }

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid>
          <Grid.Col>
            <TextInput
              required
              label="Nombre"
              withAsterisk={false}
              disabled={isPending || disabledItems?.includes('name')}
              {...form.getInputProps('name')}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required
              label="Apellidos"
              withAsterisk={false}
              disabled={isPending || disabledItems?.includes('lastname')}
              {...form.getInputProps('lastname')}
            />
          </Grid.Col>
          <Grid.Col>
            <MaskedInput
              mask={[
                {
                  mask: 'Y0000000A',
                  definitions: {
                    Y: /[XYZxyz]/,
                    A: /[A-Za-z]/,
                  },
                },
                {
                  mask: '00000000A',
                  definitions: {
                    A: /[A-Za-z]/,
                  },
                },
              ]}
              required
              label="DNI/NIE"
              withAsterisk={false}
              disabled={isPending || disabledItems?.includes('id')}
              {...form.getInputProps('id')}
            />
          </Grid.Col>
          <Grid.Col>
            <MaskedInput
              mask="000 000 000"
              required
              label="Teléfono"
              withAsterisk={false}
              disabled={isPending || disabledItems?.includes('phone')}
              {...form.getInputProps('phone')}
            />
          </Grid.Col>

          <Grid.Col>
            <TextInput
              required
              label="Dirección"
              withAsterisk={false}
              disabled={isPending || disabledItems?.includes('address')}
              {...form.getInputProps('address')}
            />
          </Grid.Col>
          <Grid.Col>
            <MaskedInput
              mask="00000"
              required
              label="Código postal"
              withAsterisk={false}
              disabled={isPending || disabledItems?.includes('cp')}
              {...form.getInputProps('cp')}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              required
              label="Ciudad"
              withAsterisk={false}
              disabled={isPending || disabledItems?.includes('city')}
              {...form.getInputProps('city')}
            />
          </Grid.Col>
          <Grid.Col>
            <Select
              required
              label="Provincia"
              data={provinces}
              withAsterisk={false}
              disabled={isPending || disabledItems?.includes('province')}
              {...form.getInputProps('province')}
            />
          </Grid.Col>
          <Grid.Col>
            <DatePickerInput
              valueFormat="DD MMM YYYY"
              defaultLevel="decade"
              required
              label="Fecha de nacimiento"
              maxDate={maxDate}
              withAsterisk={false}
              disabled={isPending || disabledItems?.includes('birth_date')}
              {...form.getInputProps('birth_date')}
            />
          </Grid.Col>
          <Grid.Col>
            <Space h="sm" />
            <Button
              loading={isPending}
              disabled={!form.isDirty()}
              fullWidth
              text="Guardar cambios"
              type="submit"
            />
          </Grid.Col>
        </Grid>
      </form>
      {isRepeated && (
        <>
          <Alert
            variant="light"
            color="red"
            title="El usuario ya existe"
            icon={<OctagonAlert />}
          >
            <Stack>
              {error}
              <Button
                onClick={handleDeleteAccount}
                variant="white"
                text="Ir a iniciar sesión"
                mt={12}
                color="red"
                loading={loading}
              />
            </Stack>
          </Alert>
        </>
      )}
    </>
  )
}
