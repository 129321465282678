import { useState } from 'react'
import { LinkCard, SignUpForm, SignInForm } from 'components'
import { Navigate } from 'react-router-dom'
import {
  Center,
  Container,
  Divider,
  SegmentedControl,
  Space,
  Text,
} from '@mantine/core'
import logo from 'assets/logo.svg'
import { Call, Global, Sms } from 'iconsax-react'
import { routes } from 'navigation/routes'
import { auth } from 'configs/firebaseConfig'

export default function SignIn() {
  const [tab, setTab] = useState('sign-in')

  if (auth.currentUser && auth.currentUser?.emailVerified)
    return <Navigate to={routes.home} replace />

  return (
    <Container py="3rem">
      <Center>
        <img src={logo} alt="certifique" style={{ height: 36 }} />
      </Center>
      <Space h="2rem" />
      <Text fw={500} fz="xl" color="brand">
        Te damos la bienvenida
      </Text>
      <Text color="dimmed" fz="sm">
        Inicia sesión para tener acceso a la información asociada a tu perfil, y
        a más características y funcionalidades.
      </Text>
      <Space h="2rem" />
      <SegmentedControl
        value={tab}
        onChange={setTab}
        fullWidth
        size="md"
        radius="md"
        data={[
          { label: 'Iniciar sesión', value: 'sign-in' },
          { label: 'Registro', value: 'sign-up' },
        ]}
        styles={{
          active: { boxShadow: 'none' },
          label: { fontSize: '0.9rem' },
        }}
      />
      <Space h="2rem" />
      {tab === 'sign-in' && <SignInFormTab />}
      {tab === 'sign-up' && <SignUpFormTab />}
    </Container>
  )
}

const SignInFormTab = () => {
  return (
    <>
      <SignInForm />
      <Space h="xl" />
      <Divider my="lg" label="Para otros trámites" labelPosition="center" />
      <LinkCard
        to="mailto:info@certifique.es"
        target="_blank"
        rel="noreferrer"
        text="info@certifique.es"
        color="#faf1e1"
        icon={<Sms />}
      />
      <Space h="sm" />
      <LinkCard
        to="tel:957477314"
        target="_blank"
        rel="noreferrer"
        text="957 47 73 14"
        color="#e6f2f4"
        icon={<Call />}
      />
      <Space h="sm" />
      <LinkCard
        to="https://certifique.es"
        target="_blank"
        rel="noreferrer"
        text="certifique.es"
        color="#eaf2e4"
        icon={<Global />}
      />
      <Text
        align="center"
        mt={68}
        py={6}
        color="dimmed"
        opacity={0.5}
        fz="xs"
      >{`v ${process.env.REACT_APP_VERSION}`}</Text>
    </>
  )
}

const SignUpFormTab = () => {
  return <SignUpForm />
}
