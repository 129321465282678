import React, { useCallback, useEffect, useReducer } from 'react'
import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  updateEmail,
  signOut as firebaseSignOut,
  sendEmailVerification,
} from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useFirebase } from 'hooks'
import { topics } from 'constants/topics'
import { useNavigate } from 'react-router-dom'
import { routes } from 'navigation/routes'
import { DEBUG } from 'utils'

let AuthContext = React.createContext([{}, () => {}])

const signupErrors = {
  'auth/email-already-in-use':
    'Ya existe una cuenta con este correo electrónico. Inicia sesión en lugar de crear una cuenta. Si si crees que alguien ha usado tu email, contacta con nosotros.',
  'auth/email-already-exists':
    'Ya existe una cuenta con este correo electrónico. Inicia sesión en lugar de crear una cuenta. Si si crees que alguien ha usado tu email, contacta con nosotros.',
  'auth/invalid-email': 'El correo electrónico no es valido.',
  'auth/invalid-password':
    'La contraseña no es valida, debe tener al menos 6 caracteres.',
}

function AuthProvider({ children }) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { loading: true, user: null, error: null, registering: false }
  )
  const {
    auth,
    createUser,
    getDataByKey,
    updateUser,
    updateCandidate,
    usersColl,
    candidatesColl,
  } = useFirebase()
  const [authUser, authLoading] = useAuthState(auth)
  const navigate = useNavigate()

  const getCandidateData = useCallback(async (uid) => {
    try {
      const candidate = await getDataByKey({
        coll: candidatesColl,
        key: 'uid',
        value: uid,
        unique: true,
      })
      if (candidate) {
        const data = candidate.data
        return { docId: candidate.docId, ...data }
      }
      return {}
    } catch (error) {
      if (DEBUG) {
        console.log({ error })
      }
      throw error
    }
  }, [])

  const getUserData = useCallback(async () => {
    if (state.user) return

    if (!authUser) {
      return setState({ user: null })
    }

    try {
      const userData = await getDataByKey({
        coll: usersColl,
        key: 'uid',
        value: authUser.uid,
        unique: true,
      })

      if (!userData) {
        throw new Error("User document doesn't exists")
        // await signOut()
      }

      if (userData.email !== authUser.email) {
        updateUser({
          data: { email: authUser.email },
          docId: userData.docId,
        })
      }

      // const testingUID = ''
      const candidateData = await getCandidateData(authUser.uid)
      // const candidateData = await getCandidateData(
      //   testingUID
      // )

      const data = {
        ...userData.data,
        userDocId: userData.docId,
        candidateDocId: candidateData?.docId,
        candidateId: candidateData?.id,
        email: authUser.email,
        picture: candidateData?.picture,
        // uid: testingUID,
      }

      setState({ user: data })
    } catch (error) {
      setState({ error })
      if (DEBUG) {
        console.log(`Error getting user data: ${error.message}`)
      }
      return { error: { message: error.message, code: error?.code } }
    } finally {
      setState({ loading: false })
    }
  }, [authUser])

  useEffect(() => {
    if (authUser) {
      getUserData()
    }
  }, [authUser])

  async function signIn({ email, password }) {
    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )

      if (!userCredentials.user.emailVerified) {
        return {
          success: false,
          error: {
            message: 'Email no verificado',
            code: 'auth/email-not-verified',
          },
        }
      }

      return { success: true, error: null }
    } catch (error) {
      const errorCode = error.code
      const errorMessage = error.message
      if (DEBUG) {
        console.log({ error })
      }
      return {
        success: false,
        error: { message: errorMessage, code: errorCode },
      }
    }
  }

  const signup = async ({ email, password }) => {
    try {
      setState({ registering: true })

      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )

      await sendEmailVerification(userCredentials.user)

      window.localStorage.setItem('emailForSignIn', email)
      return { success: true }
    } catch (error) {
      let errorMessage = error.message
      const errorCode = error?.code

      if (errorCode) {
        errorMessage =
          signupErrors[errorCode] ??
          'Se ha producido un error al crear la cuenta. Código de error: ' +
            errorCode
      }

      return { error: { message: errorMessage } }
    } finally {
      setState({ registering: false })
    }
  }

  const createUserAccount = async ({
    id,
    name,
    lastname,
    phone,
    address,
    cp,
    city,
    province,
    birthdate,
  }) => {
    try {
      // Check if there is an user with the same DNI
      const userExists = await getDataByKey({
        coll: usersColl,
        key: 'id',
        value: id,
        unique: true,
      })

      if (userExists) {
        throw {
          message:
            'Ya existe una cuenta con este DNI. Inicia sesión en lugar de crear una cuenta.',
          code: 'auth/user-already-exists',
        }
      }

      const input = {
        uid: auth.currentUser.uid,
        email: auth.currentUser.email,
        id,
        name,
        lastname,
        phone,
        address,
        cp,
        city,
        province,
        birthdate: new Date(birthdate),
        role: 'candidate',
        interests: Object.values(topics),
        createdAt: new Date(),
      }
      const { docId: userDocId, error } = await createUser(input)

      if (error) {
        // await auth.currentUser.delete()
        throw error
      }

      const data = {
        userDocId,
        email: auth.currentUser.email,
        ...input,
      }

      // Check if already has a candidate collection to add the field uid
      const candidate = await getDataByKey({
        coll: candidatesColl,
        key: 'dni',
        value: id,
        unique: true,
      })

      if (candidate) {
        data.candidateDocId = candidate.docId
        data.candidateId = candidate.data.id
        data.picture = candidate.data.picture

        const updateCandidatePromise = updateCandidate({
          docId: candidate.docId,
          data: { uid: auth.currentUser.uid },
        })
        const updateUserPromise = updateUser({
          docId: userDocId,
          data: { candidateDocId: candidate.docId },
        })
        await Promise.all([updateCandidatePromise, updateUserPromise])
      }

      setState({ loading: false, user: data })
      return { error: null }
    } catch (error) {
      return { error: { message: error.message, code: error.code } }
    }
  }

  async function signOut() {
    try {
      await firebaseSignOut(auth)
      localStorage.clear()
      setState({ user: null })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  async function getIdToken() {
    const token = await auth.currentUser.getIdToken(true)
    return token
  }

  function updateUserInterests(interests) {
    return new Promise((resolve, reject) => {
      try {
        setState({ user: { ...state.user, interests } })
        updateUser({
          docId: state.user.userDocId,
          data: {
            interests,
          },
        }).then(() => {
          resolve()
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  async function updateUserEmail({ newEmail, password }) {
    try {
      await reauthenticateWithCredential(
        authUser,
        EmailAuthProvider.credential(authUser.email, password)
      )
      window.localStorage.setItem('emailForSignIn', newEmail)
      await updateEmail(authUser, newEmail)
      await sendEmailVerification(authUser)

      return navigate(routes.emailSent, { replace: true })
    } catch (error) {
      if (DEBUG) {
        console.log({ error })
      }
      // TODO: Handle error
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        setState,
        isAuthLoading: authLoading,
        signIn,
        createUserAccount,
        signup,
        signOut,
        getIdToken,
        updateUserInterests,
        updateUserEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
