export const topics = {
  GENERAL: 'general',
  RITE: 'rite',
  RSIF: 'rsif',
  REBT: 'rebt',
  GAS: 'gas',
  GRUAS: 'gruas',
}

export const topicsDescriptions = {
  general:
    'Notificaciones de carácter general. Aquellas que no tienen relación directa con alguna de las categorías.',
  rite: 'Notificaciones relacionadas con el Reglamento de Instalaciones Térmicas en los Edificios.',
  rsif: 'Notificaciones relacionadas con el Reglamento de Seguridad en Instalaciones Frigoríficas.',
  rebt: 'Notificaciones relacionadas con el Reglamento Electrótecnico para Baja Tensión.',
  gas: 'Notificaciones relacionadas con los Reglamentos técnicos en instalaciones de Gas.',
  gruas:
    'Notificaciones relacionadas con los Reglamentos técnicos sobre Gruas Torre.',
}
