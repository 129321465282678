import { useCallback, useEffect, useReducer } from 'react'
import { Timestamp } from 'firebase/firestore'
import { useAuth, useFirebase } from 'hooks'
import { dateFormat } from 'utils'
import {
  certificateAvailabilityDate,
  emissionDate,
} from 'utils/getCertificateAvailabilityDate'

export default function useCertificates() {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { loading: false, certificates: [], count: null, error: null }
  )
  const { user } = useAuth()
  const {
    examsColl,
    getAllDocuments,
    categoriesColl,
    certificatesColl,
    getDataByKey,
  } = useFirebase()

  const getCertificates = useCallback(async (user) => {
    setState({ loading: true })

    try {
      const exams = await getAllDocuments({
        coll: examsColl(user.candidateDocId),
        orderKey: 'record',
      })

      const certs = exams.filter((exam) => {
        return typeof exam.data.certificate === 'number'
      })

      setState({ count: certs.length })

      const certificates = []

      for (const cert of certs) {
        const category = await getDataByKey({
          coll: categoriesColl,
          key: 'id',
          value: cert.data.category,
          unique: true,
        })

        const certificate = await getDataByKey({
          coll: certificatesColl(category.docId),
          key: 'candidate',
          value: user.candidateId,
          unique: true,
        })

        if (!certificate) continue

        // Only load the certificates which start date + 7 days of claims is > than today's date
        const now = Timestamp.now().toDate().toISOString().substring(0, 10)
        const closedDate = certificate.data.date.toDate()

        if (closedDate) {
          const newDate = certificateAvailabilityDate(closedDate)

          if (now < newDate) continue
        }

        const certId = String(certificate.data.id).padStart(4, '0')

        const dataObj = {
          title: category.data.name,
          code: category.data.code + certId,
          category: cert.data.category,
          fileLink:
            certificate.data && certificate.data.file.url
              ? certificate.data.file.url
              : '',
          startDate: dateFormat(emissionDate(certificate.data.date.toDate())),
          endDate: dateFormat(emissionDate(certificate.data.exp_date.toDate())),
        }
        certificates.push(dataObj)
      }

      setState({ certificates, loading: false })
    } catch (error) {
      setState({ loading: false, error })
      throw error
    }
  }, [])

  useEffect(() => {
    if (user?.candidateId) {
      getCertificates(user)
    } else {
      setState({ count: 0 })
    }
  }, [user])

  return state
}
