//Calc Theoretical exam totals
export const calcTotal = (values) => {
  const q = values.reduce((prev, { value }) => {
    prev[value] = (prev[value] || 0) + 1
    return prev
  }, {})

  const r = Object.keys(q).map((key) => ({ value: key, count: q[key] }))

  const corr = r.find((i) => i.value === 'good')?.count || 0
  const incor = r.find((i) => i.value === 'bad')?.count || 0
  const blan = r.find((i) => i.value === 'da')?.count || 0

  const calc = (corr - incor * 0.25).toFixed(2)
  const total = parseFloat(calc)

  // console.log({ q, r, corr, incor, incom, blan, total })

  return {
    corr,
    incor,
    blan,
    total,
  }
}
