import { Drawer, Switch } from '@mantine/core'
import { topics, topicsDescriptions } from 'constants/topics'
import { useAuth, useNotifications } from 'hooks'
import { useRef } from 'react'
import { differences, capitalize } from 'utils'

export default function NotificationsSettingsDrawer({ opened, close }) {
  const { user, updateUserInterests } = useAuth()
  const { subscribeToTopic, unsubscribeFromTopic } = useNotifications()
  const prev = useRef(user.interests)

  const handleChange = (value) => {
    const item = differences(prev.current, value)[0]
    const topic = item.value[0]
    const operation = item.operation
    prev.current = value

    updateUserInterests(value).then(() => {
      if (operation === 'add') {
        subscribeToTopic(topic)
      } else {
        unsubscribeFromTopic(topic)
      }
    })
  }

  return (
    <Drawer
      position="bottom"
      opened={opened}
      onClose={close}
      title="Ajustes"
      withCloseButton={false}
      padding="md"
      size="90vh"
      overlayProps={{ opacity: 0.7, blur: 4 }}
      styles={{
        content: {
          borderTopLeftRadius: '16px !important',
          borderTopRightRadius: '16px !important',
        },
      }}
    >
      <Switch.Group
        defaultValue={user.interests}
        label="Es altamente recomendable seleccionar al menos aquellas categorías en las que tienes certificación."
        onChange={handleChange}
        styles={{
          root: {
            display: 'flex',
            flexDirection: 'column',
            gap: 18,
          },
        }}
      >
        {Object.values(topics).map((topic) => (
          <Switch
            labelPosition="left"
            disabled={topic === topics.GENERAL}
            size="md"
            key={topic}
            color="teal"
            value={topic}
            label={capitalize(topic)}
            description={topicsDescriptions[topic]}
            styles={{
              body: {
                gap: 24,
                justifyContent: 'space-between',
              },
            }}
          />
        ))}
      </Switch.Group>
    </Drawer>
  )
}
