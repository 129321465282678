import {
  Alert,
  Anchor,
  Box,
  Center,
  Loader,
  Modal,
  ScrollArea,
  Skeleton,
  Stack,
  Text,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { PersonalCard, CertificateCard, Button } from 'components'
import { publicationPeriod } from 'constants/globals'
import { useAuth, useCertificates } from 'hooks'
import { Warning2 } from 'iconsax-react'
import { routes } from 'navigation/routes'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import md from '../md/use_conditions.md'
import { useMutations } from 'hooks/useMutations'

export default function Certificates() {
  return (
    <>
      <Box component="section" aria-label="Tarjeta Personal" mb={24}>
        <PersonalCard />
      </Box>
      <Box component="section" aria-label="Certificados" mb={24}>
        <Text fw={600} mb={12} fz="1.5rem" c="dark">
          Mis certificados
        </Text>
        <CertificatesList />
      </Box>
    </>
  )
}

const CertificatesList = () => {
  const { user, setState } = useAuth()
  const { loading, certificates, count, error } = useCertificates()

  if (count > 0 && !user?.use_check)
    return (
      <UseCheck
        onAccept={() => setState({ user: { ...user, use_check: true } })}
      />
    )

  if (error)
    return (
      <Alert
        title="Ups! Ha habido un error"
        icon={<Warning2 />}
        color="red"
        my={12}
        radius="lg"
      >
        <Text>
          Algo ha pasado al obtener los datos. Por favor compruebe su conexión a
          internet o intente en otro momento. Si el problema persiste, póngase
          en contacto con nosotros.
        </Text>
      </Alert>
    )

  if (loading && !count)
    return (
      <Center>
        <Loader variant="dots" />
      </Center>
    )

  if (!loading && count === 0)
    return (
      <Alert color="brand" my={12} radius="lg">
        <Text>
          Actualmente no hay ningún certificado que mostrar. Dirígete a{' '}
          <Anchor underline component={Link} to={routes.myFolder} fw={500}>
            Mi carpeta
          </Anchor>{' '}
          para ver el estado de tus exámenes.
        </Text>
      </Alert>
    )

  const diff = count - certificates.length

  return (
    <Stack>
      {loading &&
        count > 0 &&
        new Array(count)
          .fill('')
          .map((_, idx) => <Skeleton key={idx} height={230} radius="lg" />)}

      {!loading &&
        count > 0 &&
        certificates.length > 0 &&
        certificates.map((certificate) => (
          <CertificateCard key={certificate.code} {...certificate} />
        ))}

      {!loading && diff > 0 && (
        <Alert color="brand" my={12} radius="lg">
          <Text>
            {`${diff} de tus certificado${diff > 1 ? 's' : ''} está${
              diff > 1 ? 'n' : ''
            } pendiente${
              diff > 1 ? 's' : ''
            }. Por favor, ten paciencia, normalmente los certificados están disponibles ${publicationPeriod} días después de que el expediente se haya cerrado.`}
          </Text>
        </Alert>
      )}
    </Stack>
  )
}

const UseCheck = ({ onAccept }) => {
  const [opened, { toggle, close }] = useDisclosure(false)
  const [markdown, setMarkdown] = useState('')
  const { acceptUse, loading } = useMutations()

  useEffect(() => {
    fetch(md)
      .then((res) => res.text())
      .then((text) => setMarkdown(text))
  }, [])

  const handleAcceptUseConditions = async () => {
    const { error } = await acceptUse()
    if (error) {
      alert(
        'Ha habido un error al conectar con el servidor. Por favor, prueba de nuevo más tarde.'
      )
      close()
      return
    }
    close()
    onAccept && onAccept()
  }

  return (
    <>
      <Stack>
        <Text>
          Para poder acceder a tus certificados debes leer y aceptar las
          condiciones de uso de los mismos. Para ello pulsa en el siguiente
          botón.
        </Text>
        <Button onClick={toggle} text="Leer condiciones de uso" />
      </Stack>
      <Modal
        opened={opened}
        onClose={close}
        radius="lg"
        maw={490}
        mx="auto"
        centered
        scrollAreaComponent={ScrollArea.Autosize}
        title="Acuerdo de las condiciones de uso del certificado"
      >
        <Box py={2}>
          <ReactMarkdown className="md" children={markdown} />
        </Box>
        <Button
          fullWidth
          onClick={handleAcceptUseConditions}
          text="Aceptar condiciones de uso"
          loading={loading}
          mt={12}
        />
      </Modal>
    </>
  )
}
