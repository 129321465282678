import { Alert, Flex } from '@mantine/core'
import { Button } from 'components'
import { useServiceWorker } from 'hooks'

export default function InstallAppAlert() {
  const { supportsPWA, isInstalled, handleInstall } = useServiceWorker()

  const visible =
    (supportsPWA && !isInstalled) || process.env.NODE_ENV === 'development'

  // console.log({ supportsPWA, isInstalled })

  if (!visible) return null

  return (
    <Alert color="brand" my={12} radius="lg">
      <Flex justify="flex-end" align="flex-end" gap="md">
        Instala la app para acceder más fácilmente y tener una mejor experiencia
        de usuario.
        <Button onClick={handleInstall} text="Instalar" />
      </Flex>
    </Alert>
  )
}
