export const routes = {
  home: '/',
  sign_in: '/iniciar-sesion',
  emailSent: '/email-sent',
  verifyEmail: '/verify-email',
  missingUserData: '/missing-user-data',
  emailAction: '/auth/action',
  myData: 'mis-datos',
  certificates: '/certificados',
  myFolder: '/mi-carpeta',
  documents: '/documentos',
  info: '/info',
  notifications: '/notificaciones',
  request: '/solicitud',
  maintenance: '/mantenimiento',
  linkedin: 'https://www.linkedin.com//company/certifique-ingenieria',
  facebook: 'https://www.facebook.com/certificandopersonas/',
}
