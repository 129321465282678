import { useEffect, useState } from 'react'
import {
  Center,
  Paper,
  Space,
  Text,
  Image as MantineImage,
  Box,
  Stack,
  Menu,
  Flex,
  Anchor,
  Modal,
  Group,
  FileButton,
  Select,
  Skeleton,
} from '@mantine/core'
import { Button, FloatingButton, IconButton } from 'components'
import { Add, Document, Image, More, Trash } from 'iconsax-react'
import { DEBUG, dateFormat } from 'utils'
import { useAuth, useCategories, useInvoices } from 'hooks'
import { useForm } from '@mantine/form'

export default function DocumentsTab() {
  const { user } = useAuth()
  const [documents, setDocuments] = useState(null)
  const [opened, setOpened] = useState(false)
  const { getInvoices, deleteInvoice, getCategories } = useInvoices()

  useEffect(() => {
    if (user?.userDocId) {
      const invoices = async () => {
        const data = await getInvoices(user.userDocId)
        setDocuments(data)
      }
      invoices()
    }
  }, [user])

  const handleDeleteInvoice = (document, index) => {
    deleteInvoice({ userDocId: user.userDocId, invoice: document }).then(() => {
      const newDocuments = documents[index].items.filter(
        (items) => items.docId !== document.docId
      )

      setDocuments((prev) => {
        const prevItems = [].concat(prev)
        if (newDocuments.length === 0) {
          prevItems.splice(index, 1)
        } else {
          prevItems[index].items = newDocuments
        }
        return prevItems
      })
    })
  }

  const updateDocuments = async (invoice) => {
    const index = documents.findIndex(
      (obj) => obj.category.value === invoice.category
    )

    const invoiceData = {
      ...invoice,
      name: invoice.file.name,
      url: invoice.file.url,
      type: invoice.file.type,
      date: invoice.date.toDate(),
    }

    if (index === -1) {
      const categories = await getCategories()
      const categoryCode = categories.find(
        (category) => category.id === invoice.category
      )?.code

      setDocuments((prev) => [
        ...prev,
        {
          category: { value: invoice.category, label: categoryCode },
          items: [invoiceData],
        },
      ])
      return
    }

    setDocuments((prev) => {
      prev[index].items.unshift(invoiceData)
      return prev
    })
  }

  if (!documents)
    return (
      <Stack spacing="xs">
        {new Array(4).fill('').map((itm, idx) => (
          <Skeleton key={idx} height={60} radius="lg" />
        ))}
      </Stack>
    )

  return (
    <>
      {documents.length === 0 ? (
        <Center h="calc(100vh - 200px)">
          <Paper radius="lg" p="md" withBorder>
            <Box mx="auto" h={132} w={120} my={16}>
              <MantineImage src="/images/icons/folder.png" alt="Documentos" />
            </Box>
            <Text mb="sm">
              Almacena aquí las facturas, recibos o comprobantes de los trabajos
              realizados.
            </Text>
            <Text>
              Estos documentos servirán para la renovación de tus certificados.
            </Text>
            <Space h="md" />
            <Button
              onClick={() => setOpened(true)}
              fullWidth
              text="Añadir mi primer documento"
            />
          </Paper>
        </Center>
      ) : (
        <>
          <Stack pb={60} spacing="xs">
            {documents.map((group, index) => {
              return (
                <Box key={group.category.label}>
                  <Text fw={500} mb={12}>
                    {group.category.label}
                  </Text>
                  <Stack spacing="xs">
                    {group.items.map((document) => (
                      <DocumentItem
                        key={document.name}
                        onDelete={() => handleDeleteInvoice(document, index)}
                        {...document}
                      />
                    ))}
                  </Stack>
                </Box>
              )
            })}
          </Stack>
          <FloatingButton onClick={() => setOpened(true)}>
            <Add />
          </FloatingButton>
        </>
      )}

      <Modal
        opened={opened}
        onClose={() => undefined}
        centered
        radius="lg"
        withCloseButton={false}
        title="Subir documento"
        size="md"
      >
        <UploadFileForm
          onComplete={updateDocuments}
          onCancel={() => setOpened(false)}
        />
      </Modal>
    </>
  )
}

const DocumentItem = ({ name, url, type, date, onDelete = null }) => {
  const [opened, setOpened] = useState(false)

  return (
    <Paper
      key={name}
      radius="lg"
      p="xs"
      withBorder
      sx={{ position: 'relative' }}
    >
      <Anchor
        href={url}
        target="_blank"
        rel="noreferrer"
        sx={{ position: 'absolute', inset: 0 }}
      />
      <Flex justify="space-between" align="center">
        <Flex align="center" gap={12} sx={{ flex: 1, maxWidth: '65%' }}>
          {type === 'application/pdf' ? (
            <Box p="xs" bg="red.1" sx={{ borderRadius: 8 }}>
              <Text span color="red.8" display="flex">
                <Document size={22} variant="Bulk" />
              </Text>
            </Box>
          ) : (
            <Box p="xs" bg="blue.1" sx={{ borderRadius: 8 }}>
              <Text span color="blue.8" display="flex">
                <Image size={22} variant="Bulk" />
              </Text>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '100%',
              flex: 1,
            }}
          >
            <Text truncate inline lh="20px" mb={2}>
              {name}
            </Text>
            <Text truncate inline fz="xs" color="dimmed">
              {dateFormat(date, 'dd MMM yyyy')}
            </Text>
          </Box>
        </Flex>
        <Menu
          opened={opened}
          onChange={setOpened}
          shadow="md"
          width={150}
          radius="lg"
          position="bottom-end"
        >
          <Menu.Target>
            <div style={{ position: 'relative' }}>
              <IconButton radius="xl" size="xl" variant="light">
                <More />
              </IconButton>
            </div>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              onClick={onDelete}
              color="red"
              icon={<Trash size={16} />}
            >
              Eliminar
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
    </Paper>
  )
}

const UploadFileForm = ({ onComplete = () => null, onCancel = () => null }) => {
  const { user } = useAuth()
  const { categories, loading } = useCategories(user?.candidateDocId ?? null)
  const { newInvoice, loading: uploading } = useInvoices()

  const form = useForm({
    initialValues: {
      category: '',
      file: '',
    },
    validate: {
      category: (value) =>
        value !== '' ? null : 'Debes seleccionar una categoría',
      file: (value) => (value !== '' ? null : 'Debes seleccionar un archivo.'),
    },
  })

  const handleSaveDocument = (values) => {
    newInvoice({ userDocId: user.userDocId, values })
      .then((invoice) => {
        onComplete(invoice)
        onCancel()
      })
      .catch((error) => {
        if (DEBUG) {
          console.log({ error })
        }
      })
  }

  const disabled = loading || categories.length === 0 || uploading

  return (
    <form onSubmit={form.onSubmit(handleSaveDocument)}>
      <Stack>
        <Select
          disabled={disabled}
          label={'Certificación'}
          placeholder={loading ? 'Cargando...' : 'Selecciona uno'}
          required
          data={categories}
          {...form.getInputProps('category')}
        />
        <FileButton
          disabled={uploading}
          accept="image/png,image/jpeg,application/pdf"
          {...form.getInputProps('file')}
        >
          {(props) => (
            <Button
              fullWidth
              disabled={disabled}
              variant="outline"
              text={
                form.values.file ? form.values.file.name : 'Seleccionar archivo'
              }
              {...props}
            />
          )}
        </FileButton>
      </Stack>
      <Group position="right" mt={12}>
        <Button
          disabled={uploading}
          onClick={onCancel}
          text="Cancelar"
          variant="light"
        />
        <Button loading={uploading} type="submit" text="Guardar" />
      </Group>
    </form>
  )
}
